import React, { useEffect,useState,useMemo } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  CardHeader,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import { MDBDataTable } from "mdbreact";
import StarRatings from "react-star-ratings";
import { CSVLink, CSVDownload } from "react-csv";
import {useDispatch,useSelector} from 'react-redux'
import { getAllKids } from "../../redux/slices/kid.slice";
import { getAllUsers } from "../../redux/slices/user.slice";

let images = [
  require("../../assets/img/avatar/avatar-1.png"),
  require("../../assets/img/avatar/avatar-2.png"),
  require("../../assets/img/avatar/avatar-3.png"),
  require("../../assets/img/avatar/avatar-4.png"),
  require("../../assets/img/avatar/avatar-5.png"),
  require("../../assets/img/avatar/avatar-6.png"),
];

//Images
const Kids = () => {
    const dispatch = useDispatch();
    const {kids} = useSelector(state => state.kid);
    const [activeTab, setActiveTab] = useState("1");
    const [activeother, setActiveother] = useState("1");
    const [startDate, setStartDate] = useState(new Date());
    const [visible, setVisible] = useState(false);
    const [modal_standard, setModal_standard] = useState(false);
    // const [kids, setKids] = useState([]);
    const [filterResults, setFilterResults] = useState([]);
    const [modal_large, setModal_large] = useState(false);
    const [selectedKid, setSelectedKid] = useState({});

    useEffect(() => {
      dispatch(getAllKids())
    },[])

    const getImage = (image) => {
      let send;
      switch (image) {
        case 1:
          send = images[0];
          break;
        case 2:
          send = images[1];
          break;
        case 3:
          send = images[2];
          break;
        case 4:
          send = images[3];
          break;
        case 5:
          send = images[4];
          break;
        case 6:
          send = images[5];
          break;
        default:
          return images[0];
      }
      return send;
    };

    const rows = useMemo(() => {
      if (!kids) return []; // Return an empty array if kids is null or undefined
      
      return kids.map((val, index) => {
        const formattedDate = moment(val.createdAt).format("DD MMM, YYYY");
        
        return {
          ...val,
          name: (
            <span>
              {val.name}
              <p className="m-0 text-muted">
                On {formattedDate}
              </p>
            </span>
          ),
          email: val.parent ? val.parent.email : "",
          dob: moment(val.dob).format("DD MMM, YYYY"),
          avatar: (
            <span>
              <img
                style={{ width: 30, height: 30 }}
                src={getImage(val.avatar)}
              />
            </span>
          ),
          action: (
            <Button
              onClick={(e) => {
                tog_large(e, val);
              }}
              color="info"
            >
              Show Detail
            </Button>
          ),
        };
      });
    }, [kids]);
    
  
    const data = React.useMemo(() => ({
      columns: [
        {
          label: "Avatar",
          field: "avatar",
          sort: "asc",
          width: 270,
        },
        {
          label: "Name",
          field: "name",
          sort: "asc",
          width: 150,
        },
        {
          label: "School Code",
          field: "schoolCode",
          sort: "asc",
          width: 270,
        },
        {
          label: "Favourite Subject",
          field: "favouriteSubject",
          sort: "asc",
          width: 270,
        },
        {
          label: "Date of Birth",
          field: "dob",
          sort: "asc",
          width: 270,
        },
        {
          label: "Parent Email",
          field: "email",
          sort: "asc",
          width: 270,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 270,
        },
      ],
      rows: rows || [], 
    }), [rows]);

    console.log({kids})
 const tog_large = (e, val) => {
    e.preventDefault();
    setModal_large(!modal_large);
    setSelectedKid(val);
  };
 
 const tog_standard=(kids)=> {
    console.log("DONE");
    setModal_standard(!modal_standard);
  }

//   onDelete = (id, i) => {
//     let rows = this.state.data.rows;
//     let confirm = rows.filter((val) => id !== val._id);

//     this.setState({
//       data: { ...this.state.data, rows: confirm },
//     });

//     del(`${api.USERS}/${id}`, true)
//       .then(({ data }) => {
//         console.log("DELETE SUCCESS", data);
//       })
//       .catch((err) => {
//         console.log(err);
//       });
//   };

useEffect(() => {
  dispatch(getAllKids())
}, [])


 const toggleMessages = (tab) => {
    if (activeother !== tab) {
     setActiveother({
        activeother: tab,
      });
    }
  }

  const isfilterResults = async (e) => {
    if (selectedKid.results) {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = await selectedKid.results.filter((val) => {
        if (val.video.name) {
          return val.video.name.toLowerCase().includes(lowercasedFilter);
        } else {
          return val;
        }
      });
      setFilterResults({ filterResults: filteredData });
      console.log("FILTERED DATA", filteredData);
    }
  };


    return (
      <React.Fragment>
        {console.log("IS CHECK TRYE", filterResults.length > 0)}
        <div className="content dasboard-content" style={{paddingTop : '80px'}}>
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">KIDS</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      Welcome to Blinkid Kids
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Button className="btn">
                    <CSVLink
  data={kids && kids.map((val) => ({
    "Kid Avatar": val.avatar,
    "Kid Name": val.name,
    "Parent Email": val.parent ? val.parent.email : "No Parent Email Available",
    "Favourite Subject": val.favouriteSubject,
    "Date Of Birth": moment(val.dob).format("DD MMM, YYYY"),
  }))}
>
  Download
</CSVLink>


                    </Button>
                  </CardHeader>
                  <CardBody>
                    <MDBDataTable bordered data={data} />
                  </CardBody>
                </Card>

                <Modal
                  className="modal-lg"
                  isOpen={modal_large}
                  toggle={tog_large}
                >
                  <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                      {selectedKid && selectedKid.name} Detail
                    </h5>
                    <button
                      onClick={() => setModal_large(false)}
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <div
                    className="modal-body"
                    style={{ maxHeight: 700, overflowY: "auto" }}
                  >
                    <div className="form-group">
                      <label for="validationCustom01">Search</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        placeholder="Video Name"
                        style={{ height: 50 }}
                        onChange={(event) => isfilterResults(event)}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    {filterResults.length > 0
                      ? filterResults.map((val) => (
                          <Card style={{ height: 100 }}>
                            <CardBody>
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "20%" }}>
                                  <img
                                    style={{ width: 100, height: "100%" }}
                                    src={val.video && val.video.thumbnail}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "75%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Video Name:
                                    </span>{" "}
                                    {val.video && val.video.name}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Result:
                                    </span>{" "}
                                    <StarRatings
                                      rating={val.stars}
                                      starRatedColor="orange"
                                      numberOfStars={3}
                                      starSpacing="2px"
                                      starDimension="20px"
                                      name="rating"
                                    />
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))
                      : selectedKid &&
                        selectedKid.results &&
                        selectedKid.results.map((val) => (
                          <Card style={{ height: 100 }}>
                            <CardBody>
                              <div
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  display: "flex",
                                  alignItems: "center",
                                }}
                              >
                                <div style={{ width: "20%" }}>
                                  <img
                                    style={{ width: 100, height: "100%" }}
                                    src={val.video && val.video.thumbnail}
                                  />
                                </div>
                                <div
                                  style={{
                                    width: "75%",
                                    height: "100%",
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Video Name:
                                    </span>
                                    {val.video && val.video.name}
                                  </div>
                                  <div>
                                    <span style={{ fontWeight: "bold" }}>
                                      Result:
                                    </span>
                                    <StarRatings
                                      rating={val.stars}
                                      starRatedColor="orange"
                                      numberOfStars={3}
                                      starSpacing="2px"
                                      starDimension="20px"
                                      name="rating"
                                    />
                                  </div>
                                </div>
                              </div>
                            </CardBody>
                          </Card>
                        ))}
                  </div>
                </Modal>
              </Col>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }


export default Kids;
