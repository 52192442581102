/*!

=========================================================
* Argon Dashboard React - v1.2.4
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2024 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import Index from "views/Index.js";
import Profile from "views/examples/Profile.js";
import Maps from "views/examples/Maps.js";
import Register from "views/examples/Register.js";
import Login from "views/examples/Login.js";
import Tables from "views/examples/Tables.js";
import Icons from "views/examples/Icons.js";
import User from "views/User";
import Kids from "views/Kids";
import AddVideo from "views/AddVideo";
import Category from "views/category";
import Subject from "views/subject";
import Classes from "views/classes";
import SightWords from "views/sightWords";
import Subscription from "views/subscription";
import ShowVideo from "views/showVideo";
import Question from "views/Question";
import Payment from "views/Payment";
import Phonic from "views/phonic";

var routes = [
  // {
  //   path: "/index",
  //   name: "Dashboard",
  //   icon: "ni ni-tv-2 text-primary",
  //   component: <Index />,
  //   layout: "/admin",
  // },
  {
    path: "/index",
    name: "User",
    icon: "ni ni-single-02 text-yellow",
    component: <User />,
    layout: "/admin",
  },
  {
    path: "/kids",
    name: "Kids",
    icon: "ni ni-single-02 text-yellow",
    component: <Kids />,
    layout: "/admin",
  },
  {
    path: "/add-video",
    name: "Add Video",
    icon: "ni ni-single-02 text-yellow",
    component: <AddVideo />,
    layout: "/admin",
  }, 
  {
    path: "/category",
    name: "Category",
    icon: "ni ni-single-02 text-yellow",
    component: <Category /> ,
    layout: "/admin",
  },
  {
    path: "/subject",
    name: "Subject",
    icon: "ni ni-single-02 text-yellow",
    component: <Subject /> ,
    layout: "/admin",
  },
  {
    path: "/classes",
    name: "Classes",
    icon: "ni ni-single-02 text-yellow",
    component: <Classes /> ,
    layout: "/admin",
  },
  {
    path: "/sightwords",
    name: "Sight Words",
    icon: "ni ni-single-02 text-yellow",
    component: <SightWords /> ,
    layout: "/admin",
  },
  {
    path: "/subscriptions",
    name: "Subscriptions",
    icon: "ni ni-single-02 text-yellow",
    component: <Subscription /> ,
    layout: "/admin",
  },
  {
    path: "/showvideo",
    name: "Show Video",
    icon: "ni ni-single-02 text-yellow",
    component: <ShowVideo /> ,
    layout: "/admin",
  },
  {
    path: "/question",
    name: "Question",
    icon: "ni ni-single-02 text-yellow",
    component: <Question /> ,
    layout: "/admin",
  },
  {
    path: "/payment",
    name: "Payment",
    icon: "ni ni-single-02 text-yellow",
    component: <Payment /> ,
    layout: "/admin",
  },
  {
    path: "/phonic",
    name: "Phonic",
    icon: "ni ni-single-02 text-yellow",
    component: <Phonic /> ,
    layout: "/admin",
  },
  // {
  //   path: "/icons",
  //   name: "Icons",
  //   icon: "ni ni-planet text-blue",
  //   component: <Icons />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/maps",
  //   name: "Maps",
  //   icon: "ni ni-pin-3 text-orange",
  //   component: <Maps />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/user-profile",
  //   name: "User Profile",
  //   icon: "ni ni-single-02 text-yellow",
  //   component: <Profile />,
  //   layout: "/admin",
  // },
  // {
  //   path: "/tables",
  //   name: "Tables",
  //   icon: "ni ni-bullet-list-67 text-red",
  //   component: <Tables />,
  //   layout: "/admin",
  // },
  {
    path: "/",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: <Login />,
    layout: "/auth",
  },
  // {
  //   path: "/register",
  //   name: "Register",
  //   icon: "ni ni-circle-08 text-pink",
  //   component: <Register />,
  //   layout: "/auth",
  // },
];
export default routes;
