import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  CardHeader,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
// import { api } from "../../../helpers/apiLinks";
// import { put } from "../../../helpers/apiCalls";
import { MDBDataTable } from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { editSubscription, getAllSubscriptions } from "../../redux/slices/user.slice";

const columns = [
    {
      dataField: "package",
      text: "Subscription Type",
    },
    {
      dataField: "price",
      text: "Amount",
    },
    {
      dataField: "priceId",
      text: "Stripe Price Id",
    },
    { dataField: "paypal_planId", text: "Paypal Plan Id" },
    {
      dataField: "action",
      text: "Get Stripe Price Id",
    },
  ]

const Subscription = () => {
  const dispatch = useDispatch();
  const {subscription, success} = useSelector(state => state.user)

  useEffect(() => {
    // activateAuthLayout();
    dispatch(getAllSubscriptions())
  }, []);


  const handleSaveCell = (oldValue, newValue, row, column) => {
    console.log(oldValue, newValue, row, column);
    // dispatch(
    //   put(`${api.SUBSCRIPTION}${row._id}`, { [column.dataField]: newValue }, true)
    // )
    //   .then(({ data }) => {
    //     console.log("CHANGE DATA", data);
    //   })
    //   .catch((err) => {
    //     console.log("ERROR AGYA BHAIYA", err);
    //   });
  };

  const data = React.useMemo(() => 
  subscription && subscription?.map((val, index) => {
      return {
          action: (
            <>
            <Button
                onClick={() =>
                  window.open(
                    "https://dashboard.stripe.com/login?redirect=https%3A%2F%2Fstripe.com%2Fdocs%2Fdevelopment",
                    "_blank"
                  )
                }
              >
                Get Stripe Price Id
              </Button>
            </>
          ),
          ...val,
        };
                })
, [subscription]
);


  console.log(subscription)

  return (
    <React.Fragment>
      <div className="content dasboard-content"  style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Payments</h4>
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {/* <BootstrapTable
                    keyField="package"
                    data={data}
                    columns={columns}
                    cellEdit={cellEditFactory({
                      mode: "click",
                      afterSaveCell: handleSaveCell,
                    })}
                  /> */}
                    <BootstrapTable
                      keyField="package"
                      data={data}
                      columns={columns}
                      cellEdit={cellEditFactory({
                        mode: "click",
                        afterSaveCell: (oldValue, newValue, row, column) => {
                          console.log(oldValue, newValue, row, column);
                          dispatch(editSubscription({id: row._id, [column.dataField]: newValue}))
                          // put(
                          //   `${api.SUBSCRIPTION}${row._id}`,
                          //   { [column.dataField]: newValue },
                          //   true
                          // )
                          //   .then(({ data }) => {
                          //     console.log("CHANGE DATA", data);
                          //   })
                          //   .catch((err) => {
                          //     console.log("ERROR AGYA BHAIYA", err);
                          //   });
                        },
                      })}
                    />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subscription
