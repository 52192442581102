// kidSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import axios from 'axios';
// import { login } from './auth.actions';



const initialState = {
    isLoading: false,
    error: null,
    kids: [],
    kidsCount: 0,
    schoolCount: 0,
    totalUsers: 0,
    usersCount: 0
};

export const deleteUser = createAsyncThunk(
    'kid/deleteUser',
    async (id) => {
        const response= await api.delete(`/user/${id}`)
        return {data:response.data,id}
    }
)

export const getAllKids = createAsyncThunk(
    'kid/getAllKids',
    async () => {
        const response= await api.get('/kid')
        return response.data
    }
)


// Continuing in kidSlice.ts

const kidSlice = createSlice({
    name: 'kid',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllKids.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllKids.fulfilled, (state, action) => {
                console.log(action.payload)
                state.kids = action.payload.kids
                state.isLoading = false;
                
            })
            .addCase(getAllKids.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
    }
});

// export const {  } = kidSlice.actions;
export default kidSlice.reducer;
