import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
// import { get, del } from "../../../helpers/apiCalls";
// import { api } from "../../../helpers/apiLinks";
import { MDBDataTable } from "mdbreact";
import moment from "moment";
import { Card, CardBody, Col, Row } from "reactstrap";
import { getAllVideos } from "../../redux/slices/user.slice";


let images = [
  require("../../assets/img/avatar/avatar-1.png"),
  require("../../assets/img/avatar/avatar-2.png"),
  require("../../assets/img/avatar/avatar-3.png"),
  require("../../assets/img/avatar/avatar-4.png"),
  require("../../assets/img/avatar/avatar-5.png"),
  require("../../assets/img/avatar/avatar-6.png"),
];


const ShowVideo = () => {
  const { videos, success } = useSelector(state => state.user)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getAllVideos())
  }, []);

  console.log({ videos })


  const getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };

  const onDelete = (id, i) => {
    // let rows = data.rows;
    // let confirm = rows.filter((val) => id !== val._id);
    // setData({ ...data, rows: confirm });

    // del(`${api.USERS}/${id}`, true)
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const rows = React.useMemo(() =>
    videos && videos?.map((val, index) => {
      return {
        ...val,
        name: (
          <span>
            {val.name}
            <p className="m-0 text-muted">
              On {moment(val.created).format("DD MMM, YYYY")}
            </p>
          </span>
        ),
        email: val.parent && val.parent.email,
        dob: moment(val.created).format("DD MMM, YYYY"),
        avatar: (
          <span>
            <img
              style={{ width: 30, height: 30 }}
              src={getImage(val.avatar)}
            />
          </span>
        ),
      };
    })
    , [videos]
  );

  console.log({ videos })

  const data = React.useMemo(() => ({
    columns: [
      {
        label: "Avatar",
        field: "avatar",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "School Code",
        field: "schoolCode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Favourite Subject",
        field: "favouriteSubject",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date of Birth",
        field: "dob",
        sort: "asc",
        width: 270,
      },
      {
        label: "Parent Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
    ],
    rows
  }), [rows]);

  return (
    <React.Fragment>
      <div className="content dasboard-content" style={{ paddingTop: '80px' }}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">KIDS</h4>
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">Welcome to Blinkid Kids</li>
                </ol>
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  <h4 className="mt-0 header-title">Basic Data Table</h4>
                  <p className="text-muted m-b-30">
                    mdbreact DataTables has most features enabled by default, so all you need to do to use it with your own tables is to call the construction function: <code>&lt;MDBDataTable /&gt;</code>.
                  </p>

                  <MDBDataTable bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default ShowVideo;
