// authSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import axios from 'axios';
// import { login } from './auth.actions';

const initialState = {
    token: null,
    email: null,
    isLoading: false,
    error: null,
    user: null,
    isAuthenticated: false,
};


export const getToken = createAsyncThunk(
    'auth/getToken',
    async (data) => {
        const response = await api.post('/user/login', data);
        return response.data;
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async (user, thunkApi) => {
        const response= await api.post('/user/login', user)
        return response.data
    }
)



// Continuing in authSlice.ts

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        setLogout: (state, action) => {
            localStorage.removeItem("token")
            state.token = null;
            state.email = null;
            state.user = null;
            state.error = null;
            state.isAuthenticated = false
        },
        resetAuthError: (state) => {
            state.error = null;
            state.resetPassword = null
        },
        setToken: (state, action) => {
            state.token = action.payload;
        },
        Login_Pending: (state, action) => {

            state.isLoading = true;
            state.error = null;
            state.isAuthenticated = false
        },
        Login_Success: (state, action) => {
            localStorage.setItem('token', action.payload.token)
            state.isLoading = false;
            state.isAuthenticated = true
            state.user = action.payload?.data?.user
            state.email = action.payload?.data?.user?.email;
        },
        Login_Error: (state, action) => {
            state.isLoading = false;
            state.error = action.payload?.err?.message || 'Something went wrong!';
            state.isAuthenticated = false
        },
        Logout:(state,action)=>{
            state.isAuthenticated = false;
            state.user = null;
            state.token = null
        }


        //   ... You can handle other actions similarly: signup, verifyEmail, forgotPassword
    },
    extraReducers: (builder) => {
        builder
            .addCase(login.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(login.fulfilled, (state, action) => {
                if(action.payload.success === false) {
                    state.isLoading = false;
                    state.error = action.payload.msg
                    return
                }
                state.user = action.payload.user
                state.token = action.payload.token
                state.isAuthenticated = true
                state.isLoading = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
    }
});

export const { resetAuthError } = authSlice.actions;
export default authSlice.reducer;
