import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import classnames from "classnames";
// import { api } from "../../../helpers/apiLinks";
import { ToastContainer, toast } from "react-toastify";
// import { del, get, post, put } from "../../../helpers/apiCalls";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Loader from "react-loader-spinner";
import BootstrapTable from "react-bootstrap-table-next";
import { Button } from "reactstrap";
import { addClass, deleteClass, editClass, getAllClass } from "../../redux/slices/user.slice";

const columns = [
  {
    dataField: "name",
    text: "Class Name",
  },

  {
    dataField: "action",
    text: "Delete Class",
  },
];

const Classes = () => {
//   const [data, setData] = useState([]);
const { class: Classes, success } = useSelector(state => state.user);

  const [activeTab1, setActiveTab1] = useState("5");
  const [className, setClassName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [classId, setClassId] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getAllClass())
  }, []);

  const notify = (msg) => toast(`Class ${msg ? msg : "Added"} Successfully`);

  const handleDeleteClass = (id) => {
    dispatch(deleteClass(id))
  };

  const handleChangeClass = () => {
    if (className === "") {
      setErr(true);
      setErrMsg("Please Fill out the Fields!");
    } else {
    dispatch(addClass({className}));
    }
  };

  const handleEditClass = () => {
    let id = classId;
    dispatch(editClass({id, name : className}))
    if(success === "Class successfully edited"){
        setActiveTab1("6")
    }
  };


  const handleEditClick = (val) => {
    setClassId(val._id);
    setIsEdit(true);
    setClassName(val.name);
    setActiveTab1("5");
  };

  const toggle1 = (cur) => {
    setActiveTab1(cur);
  };

  const data = useMemo(() => {
    return (
        Classes &&
        Classes.map((val, index) => {
        return {
          ...val,
          action: (
            <>
              <Button
                      onClick={() =>
                        handleEditClick(val)
                      }
                      className="btn btn-primary"
                    >
                      {" "}
                      Edit{" "}
                    </Button>
                    <Button
                      onClick={() => handleDeleteClass(val._id)}
                      className="btn btn-danger"
                    >
                      {" "}
                      Delete{" "}
                    </Button>
            </>
          ),
        };
      })
    );
  }, [Classes]);

  return (
    <React.Fragment>
      <div className="content dasboard-content" style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Classes</h4>
                <ToastContainer />
              </div>
            </div>
          </div>
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab1 === "5",
                })}
                onClick={() => {
                  toggle1("5");
                }}
              >
                Add Class
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab1 === "6",
                })}
                onClick={() => {
                  toggle1("6");
                }}
              >
                Show Class
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab1}>
            <TabPane tabId="5" className="p-3">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4 className="mt-0 header-title">Add Class</h4>
                      <AvForm>
                        <AvField
                          name="Min_Length"
                          label="Min Length : "
                          placeholder="PN"
                          type="text"
                          errorMessage="Min 1 chars."
                          value={className}
                          onChange={(e) => setClassName(e.target.value)}
                          validate={{
                            required: { value: true },
                            minLength: {
                              value: 1,
                              errorMessage: "Min 1 chars.",
                            },
                          }}
                        />
                        <div className="form-group  m-b-0">
                          <Button
                            onClick={isEdit ? handleEditClass : handleChangeClass}
                            color="primary"
                          >
                            {loading ? (
                            //   <Loader
                            //     type="TailSpin"
                            //     color="#00BFFF"
                            //     height={20}
                            //     width={20}
                            //   />
                            "loading"
                            ) : isEdit ? (
                              "Update"
                            ) : (
                              "Submit"
                            )}
                          </Button>{" "}
                          &nbsp;
                          {isEdit && (
                            <Button
                              type="reset"
                              onClick={() => {
                                setIsEdit(false);
                                setClassName("");
                                setClassId("");
                              }}
                            >
                              cancel
                            </Button>
                          )}
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
          <TabContent activeTab={activeTab1}>
            <TabPane tabId="6" className="p-3">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <BootstrapTable
                        keyField="package"
                        data={data}
                        columns={columns}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Classes;
