import React, { useEffect, useState,useMemo } from "react";
import {
  FileLibraryListItem,
  ReactMediaLibrary,
  FileMeta,
} from "react-media-library";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  TabContent,
  Button,
  CardText,
} from "reactstrap";
// import { api } from "../../../helpers/apiLinks";
// import { del, get, post } from "../../../helpers/apiCalls";
import Iframe from "react-iframe";
// import Settingmenu from "../Subpages/Settingmenu";
import classnames from "classnames";
import { Link } from "react-router-dom";
import ReactAudioPlayer from "react-audio-player";
import Dropzone from "react-dropzone-uploader";
import copy from "copy-to-clipboard";
import "react-dropzone-uploader/dist/styles.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { useDispatch, useSelector } from "react-redux";
import { getAudios, getImages, getVideos, uploadAssets } from "../../redux/slices/user.slice";
import { BASIC_URL } from "utils/api";

const ImageModal = ({
  activeTab1,
  setActiveTab,
  display,
  setDisplay,
  select,
}) => {
  // const [display, setDisplay] = useState(false);
  const [file, setFile] = useState({});
  const [fileLibraryList, setFileLibraryList] = useState([]);
  // const [activeTab1, setActiveTab] = useState("5");
  const [state, setState] = useState({});
  const [oldImages, setOldImages] = useState([]);
  const [oldAudios, setOldAudios] = useState([]);
  const [oldVideos, setOldVideos] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [urlll, setUrlll] = useState(false);
  const [success_dlg, setSuccess_dlg] = useState(false);
  const [dynamic_description, setDynamicDescription] = useState();
  const [dynamic_title, setDynamicTitle] = useState()
  const [confirm_both, setConfirm_both] = useState(false);
  const [id, setId] = useState()
  const [searchImg, setSearchImg] = useState("")
  const [searchVid, setSearchVid] = useState("")
  const [searchAud, setSearchAud] = useState("")
  const { users, totalUsers, schoolCount, kidsCount, usersCount, category, files, videos, audios, images } = useSelector(state => state.user)
  const searchedImages = useMemo(() => images?.filter((val) =>
    val.originalname.toLowerCase().includes(searchImg)
  ), [images,searchImg])
  const searchedVideos = useMemo(() => videos?.filter((val) =>
    val.originalname.toLowerCase().includes(searchVid)
  ), [videos,searchVid])
  const searchedAudios = useMemo(() => audios?.filter((val) =>
    val.originalname.toLowerCase().includes(searchAud)
  ), [audios,searchAud])


  useEffect(() => {
    getAll()
  }, []);


  const getAll = () => {
    dispatch(getAudios());
    dispatch(getVideos());
    dispatch(getImages());
  };
  const tog_video = () => {
    setIsOpen(!isOpen);
  };
  const getFiles = (key) => {
    // get(api[key], true)
    //   .then(({ data }) => {
    //     console.log(key, data);

    //     if (key.toLowerCase() == "images") {
    //       setImages(data[key.toLowerCase()]);
    //       setOldImages(data[key.toLowerCase()]);
    //     } else if (key.toLowerCase() == "audios") {
    //       setAudios(data[key.toLowerCase()]);
    //       setOldAudios(data[key.toLowerCase()]);
    //     } else if (key.toLowerCase() == "videos") {
    //       setVideos(data[key.toLowerCase()]);
    //       setOldVideos(data[key.toLowerCase()]);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
  const getUploadParams = ({ meta }) => {
    return { url: "https://httpbin.org/post" };
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setFile(file);
  };
  const dispatch = useDispatch()
  const uploadFile = (files, allFiles) => {
    let dummy = {
      image: "images",
      audio: "audios",
      video: "videos",
    };
    files.length &&
      files.map((val) => {
        let file = new FormData();
        file.append("file", val.file);
        let type = val.file.type.split("/")[0];
        console.log("DATATTA", val.file);
        dispatch(uploadAssets(file));
        // if(files){
        getAll();
        // allFiles.forEach((f) => f.remove());
        // setState({
        //   ...allFiles,
        //   [dummy[type]]: [
        //     ...allFiles[dummy[type]],
        //     { ...data, originalname: data.name },
        //   ],
        // });
        // return data;
        // }
        // return post(api.FILE, file, true)
        //   .then(({ data }) => {
        //     console.log("COMPLETED", data);
        //     getAll();
        //     allFiles.forEach((f) => f.remove());
        //     // setState({
        //     //   ...allFiles,
        //     //   [dummy[type]]: [
        //     //     ...allFiles[dummy[type]],
        //     //     { ...data, originalname: data.name },
        //     //   ],
        //     // });
        //     return data;
        //   })
        //   .catch((err) => {
        //     console.log("ERR", err);
        //   });
      });
  };

  const uploadCallback = async (fileBase64, fileMeta) => {
    // TODO Upload file to backend APIs
    const result = await uploadFile({
      ...fileMeta,
      filename: fileMeta.fileName,
      mimetype: fileMeta.type,
      encoding: "7bit",
    });
    console.log("FILE BRO", result, fileMeta);

    //   if (result.success) {
    //       // New file uploaded
    //       // TODO Reacquire new file list from database
    //       const newFileList = await getNewFileList();
    //       setFileLibraryList(newFileList);

    //       // Return true to display upload success in modal
    //       return true;
    //   } else {
    //       // Return false to display upload failed in modal
    //       return false;
    //   }
  }

  const selectCallback = (item) => {
    console.log("FILE", item);
    // Hide modal
    // setDisplay(false);

    // TODO Pass selected file back to client component callback function
    //   clientSelectCallback(item);
  };
  const funcToggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setActiveTab(tab);
    }
  };

  // async function deleteCallback(item) {
  //     // TODO Delete file from backend service
  //     const result = await fileDelete(item);

  //     if (result.success) {
  //         // Deleted file
  //         // TODO Reacquire file list from database
  //         const newFileList = await getNewFileList();
  //         setFileLibraryList(newFileList);
  //     }
  // }

  const tog_large = () => {
    setDisplay(!display);
  };

  //   const onDeleteFile = (id) => {
  //     console.log("HI HELLO");
  //     del(`${api.FILE}/${id}`, true)
  //       .then(({ data }) => {
  //         console.log("DELETED FILE BA", data);
  //         setState({
  //           confirm_both: false,
  //           success_dlg: true,
  //           dynamic_title: "Deleted",
  //           dynamic_description: "Your file has been deleted.",
  //         });
  //         if (data && data.success) {
  //           getFiles("AUDIOS");
  //           getFiles("IMAGES");
  //           getFiles("VIDEOS");
  //         }
  //       })
  //       .catch((err) => {
  //         console.log("ERROR", err);
  //       });
  //   };

  return (
    <Modal className="modal-lg" isOpen={display} toggle={tog_large}>
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myLargeModalLabel">
          Media Library
        </h5>

        {confirm_both ? (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            // onConfirm={() => {
            //   onDeleteFile(state.id);
            // }}
            onCancel={() =>
              setState({
                confirm_both: false,
                error_dlg: true,
                dynamic_title: "Cancelled",
                dynamic_description: "Your imaginary file is safe :)",
              })
            }
          >
            You won't be able to revert this!
          </SweetAlert>
        ) : null}

        {success_dlg ? (
          <SweetAlert
            success
            title={dynamic_title}
            onConfirm={() => setSuccess_dlg(true)}
          >
            {dynamic_description}
          </SweetAlert>
        ) : null}
        <button
          onClick={() => setDisplay(false)}
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        <Nav pills className="navtab-bg nav-justified">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab1 === "5",
              })}
              onClick={() => {
                funcToggle1("5");
              }}
            >
              Upload File
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab1 === "6",
              })}
              onClick={() => {
                funcToggle1("6");
              }}
            >
              Images
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab1 === "7",
              })}
              onClick={() => {
                funcToggle1("7");
              }}
            >
              Songs
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab1 === "8",
              })}
              onClick={() => {
                funcToggle1("8");
              }}
            >
              Videos
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab1}>
          <TabPane tabId="5" className="p-3">
            <Row>
              <Col sm="12">
                <Dropzone
                  getUploadParams={getUploadParams}
                  onChangeStatus={handleChangeStatus}
                  accept="image/*,audio/*,video/*"
                  maxFiles={5}
                  onSubmit={uploadFile}
                />
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6" className="p-3">
            <Row>
              <Col sm="12">
                <div style={{ paddingBottom: 20 }}>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="Search"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      let value = event.target.value.toLowerCase();
                      setSearchImg(value)
                      // if (oldImages && oldImages.length > 0) {
                      //   let filterData = oldImages.filter((val) =>
                      //     val.originalname.toLowerCase().includes(value)
                      //   );
                      //   setImages(filterData);
                      // }
                    }}
                  />
                </div>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {searchedImages && searchedImages?.map((val, key) => (
                    <Card key={key}>
                      <CardBody>
                        <div
                          style={{
                            height: 250,
                            width: 200,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <div
                            style={{
                              position: "absolute",
                              right: 20,
                              fontSize: 24,
                              padding: 5,
                              color: 'black',
                              backgroundColor: "rgba(255,255,255, 0.7)",
                            }}
                            onClick={(e) => {
                              setConfirm_both(true)
                              setId(val._id);
                            }
                            }
                          >
                            <i
                              onClick={(e) => {
                                setConfirm_both(true)
                                setId(val._id);
                              }
                              }
                              className={`mdi mdi-trash-can-outline text-danger`}
                            ></i>
                          </div>
                          <img
                            src={`${BASIC_URL}${val.url}`}
                            style={{
                              height: "80%",
                              width: "100%",
                            }}
                          />
                          <div
                            style={{
                              color: "black",
                              height: "20%",
                              display: "flex",
                              overflow: "hidden",
                              maxWidth: "90%",
                              alignItems: "center",
                            }}
                          >
                            {val.originalname}
                          </div>
                          <div
                            onClick={() => {
                              select(val);
                              setDisplay(false);
                            }}
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 5,
                              paddingBottom: 5,
                              marginLeft: 10,
                              border: "1px solid white",
                              cursor: "pointer",
                              color: "black",
                            }}
                          >
                            Select
                          </div>
                        </div>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="7" className="p-3">
            <Row>
              <Col sm="12">
                <div style={{ paddingBottom: 20 }}>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="Search"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      let value = event.target.value.toLowerCase();
                      setSearchAud(value)
                      // if (oldAudios && oldAudios.length > 0) {
                      //   let filterData = oldAudios.filter((val) =>
                      //     val.originalname.toLowerCase().includes(value)
                      //   );
                      //   setAudios(filterData);
                      // }
                    }}
                  />
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    flexWrap: "wrap",
                  }}
                >
                  {searchedAudios && searchedAudios?.map((val) => (
                    <Card>
                      <CardBody>
                        <div
                          style={{
                            width: "100%",
                            height: 50,
                            display: "flex",
                            overflow: "hidden",
                            color: "black",
                            justifyContent: "center",
                            alignItems: "center",

                          }}
                        >
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <i
                              onClick={() => {
                                setUrlll(val.url);
                                setIsOpen(true);
                              }}
                              style={{ fontSize: 24, cursor: "pointer", color: "black" }}
                              className={`mdi mdi-play text-primary`}
                            ></i>
                            {val.originalname}
                          </div>
                          <div
                            onClick={() => {
                              select(val);
                              setDisplay(false);
                            }}
                            style={{
                              paddingLeft: 10,
                              paddingRight: 10,
                              paddingTop: 5,
                              paddingBottom: 5,
                              marginLeft: 10,
                              border: "1px solid white",
                              cursor: "pointer",
                              color: "black",
                            }}
                          >
                            Select
                          </div>
                          <i
                            onClick={(e) => {
                              setConfirm_both(true)
                              setId(val._id)
                            }
                            }
                            style={{
                              cursor: "pointer",
                              marginLeft: 10,
                              fontSize: 24,
                              color: "black",
                            }}
                            className={`mdi mdi-trash-can-outline text-danger`}
                          ></i>
                        </div>
                      </CardBody>
                    </Card>
                  ))}
                </div>
              </Col>
            </Row>
          </TabPane>

          <TabPane tabId="8" className="p-3">
            <Row>
              <Col sm="12">
                <div style={{ paddingBottom: 20 }}>
                  <input
                    type="text"
                    className="form-control"
                    id="validationCustom01"
                    placeholder="Search"
                    style={{ width: "100%" }}
                    onChange={(event) => {
                      let value = event.target.value.toLowerCase();
                      setSearchVid(value)
                      // if (oldVideos && oldVideos.length > 0) {
                      //   let filterData = oldVideos.filter((val) =>
                      //     val.originalname.toLowerCase().includes(value)
                      //   );
                      //   setVideos(filterData);
                      // }
                    }}
                  />
                </div>
                {searchVid && searchVid?.map((val) => (
                  <Card>
                    <CardBody>
                      <div
                        style={{
                          width: "100%",
                          height: 50,
                          display: "flex",
                          overflow: "hidden",
                          color: "black",
                          justifyContent: "center",
                          alignItems: "center",
                        }}
                      >
                        <i
                          onClick={() => {
                            setUrlll(val.url);
                            setIsOpen(true);
                          }}
                          style={{ fontSize: 24, cursor: "pointer" }}
                          className={`mdi mdi-play text-primary`}
                        ></i>
                        <span style={{ marginLeft: 10 }}>
                          {val.originalname}
                        </span>
                        <div
                          onClick={() => copy(val.url)}
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginLeft: 10,
                            border: "1px solid white",
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          Copy
                        </div>
                        <div
                          onClick={() => {
                            select(val);
                            setDisplay(false);
                          }}
                          style={{
                            paddingLeft: 10,
                            paddingRight: 10,
                            paddingTop: 5,
                            paddingBottom: 5,
                            marginLeft: 10,
                            border: "1px solid white",
                            cursor: "pointer",
                            color: "black",
                          }}
                        >
                          Select
                        </div>
                        <i
                          onClick={(e) => {
                            setConfirm_both(true)
                            setId(val._id)
                          }
                          }
                          style={{
                            cursor: "pointer",
                            marginLeft: 10,
                            fontSize: 24,
                          }}
                          className={`mdi mdi-trash-can-outline text-danger`}
                        ></i>
                      </div>
                    </CardBody>
                  </Card>
                ))}
              </Col>
            </Row>
          </TabPane>
        </TabContent>
        <Modal className="modal-lg" isOpen={isOpen} toggle={tog_video}>
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myLargeModalLabel">
              Video Player
            </h5>
            <button
              onClick={() => setIsOpen(false)}
              type="button"
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <Iframe
              url={urlll}
              width="100% "
              height="300px"
              id="myId"
              className="myClassname"
              display="initial"
              position="relative"
            />
          </div>
        </Modal>
      </div>
    </Modal>
  );
};

export default ImageModal;
