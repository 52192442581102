import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Alert,
  Modal,
  CardHeader,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classnames from "classnames";
import ReactAudioPlayer from "react-audio-player";
// import { api } from "../../../helpers/apiLinks";
// import { del, get, post, put } from "../../../helpers/apiCalls";
import Select from "react-select";
// import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
import ImageModal from "components/imageGallery";
import { addSightWords, addWords, deleteSightWords, editSightWords, getAllSightWords, getAllSubscriptions } from "../../redux/slices/user.slice";
import swal from 'sweetalert';

let images = [
    require("../../assets/img/avatar/avatar-1.png"),
    require("../../assets/img/avatar/avatar-2.png"),
    require("../../assets/img/avatar/avatar-3.png"),
    require("../../assets/img/avatar/avatar-4.png"),
    require("../../assets/img/avatar/avatar-5.png"),
    require("../../assets/img/avatar/avatar-6.png"),
  ];

const Offsymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      Yes
    </div>
  );
};

const SightWords = (props) => {
  const dispatch = useDispatch()
  const [activeTab, setActiveTab] = useState("1");
  const [activeother, setActiveOther] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [totalUsers, setTotalUsers] = useState("");
  const [schoolCount, setSchoolCount] = useState("");
  const [kidsCount, setKidsCount] = useState("");
  const [visible, setVisible] = useState(false);
  const [modalStandard, setModalStandard] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [activeTab1, setActiveTab1] = useState("5");
  const [selectedMulti, setSelectedMulti] = useState(null);
  const [level, setLevel] = useState("");
  const [isError, setIsError] = useState(false);
  const [kids, setKids] = useState([]);
  const [name, setName] = useState("");
  const [category, setCategory] = useState([]);
  const [sightIdVal, setSightIdVal] = useState();
  const [alert14, setAlert14] = useState()
  const [errorDlg, setErrorDlg] = useState()
  const [id, setId] = useState()
  const [idVal, setIdVal] = useState()
  const [classes, setClasses] = useState([
    {
      label: "Classes",
      options: [],
    },
  ]);
  const [types, setTypes] = useState([
    {
      label: "Types",
      options: [
        { label: "Special", value: "specials" },
        { label: "Classic", value: "classic" },
        { label: "Song", value: "songs" },
      ],
    },
  ]);
  const [word, setWord] = useState("");
  const [currentClass, setCurrentClass] = useState("");
  const [type, setType] = useState("");
  const [grade, setGrade] = useState("");
  const [sight, setSight] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [audio, setAudio] = useState("");
  const [classSearch, setClassSearch] = useState("");
  const [isAdd, setIsAdd] = useState(false);
  const [data2, setData2] = useState([]);
  const [display, setDisplay] = useState(false);
  const [ShowWords, setShowWords] = useState(false);
  const [sightWord, setSightWord] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [addWord, setAddWord] = useState("");
  const [editId, setEditId] = useState("");
  const [sightId, setSightId] = useState("");
  const [confirmBoth, setConfirmBoth] = useState(false);
  const [confirmBoth1, setConfirmBoth1] = useState(false);
  const [successDlg, setSuccessDlg] = useState(false);
  const [dynamicTitle, setDynamicTitle] = useState("");
  const [dynamicDescription, setDynamicDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [ModalShowWords, setModalShowWords] = useState(false)
  const {sight : Sight, success} = useSelector(state => state.user)



  useEffect(() => {
    // getSightWords();
    // props.activateAuthLayout();
    dispatch(getAllSightWords())
  }, []);

//   const getSightWords = () => {
//     get(`sights`, true)
//       .then(({ data }) => {
//         console.log("DATA IS HERE", data.sightWord);
//         let data2 = {
//           columns: [
//             {
//               label: "Level",
//               field: "level",
//               sort: "asc",
//               width: 270,
//             },
//             {
//               label: "Grade",
//               field: "grade",
//               sort: "asc",
//               width: 150,
//             },
//             {
//               label: "Action",
//               field: "action",
//               sort: "asc",
//               width: 270,
//             },
//           ],
//           rows:
//             data.sightWord.length > 0 &&
//             data.sightWord.map((val, index) => {
//               console.log(val);
//               return {
//                 action: (
//                   <>
//                     <Button
//                       onClick={() =>
//                         setShowWords(true);
//                       }}
//                       type="submit"
//                       color="success"
//                       className="mr-1"
//                     >
//                       Show
//                     </Button>
//                     <Button
//                       onClick={() => {
//                         setSight(val.sight);
//                         setLevel(val.level);
//                         setGrade(val.grade);
//                         setEditId(val._id);
//                         setCurrentClass({ label: "", value: val._id });
//                         setIsEdit(true);
//                         var elem = document.getElementById("body123");
//                         if (elem) {
//                           elem.scrollIntoView();
//                         }
//                       }}
//                       type="submit"
//                       color="primary"
//                       className="mr-1"
//                     >
//                       Edit
//                     </Button>

//                     <Button
//                       onClick={(e) =>
//                         setConfirmBoth1(true);
//                         setId(val._id);
//                       }
//                       type="submit"
//                       color="danger"
//                       className="mr-1"
//                     >
//                       Delete
//                     </Button>
//                   </>
//                 ),
//                 ...val,
//               };
//             }),
//         };
//         console.log({ data2 });
//         data.sightWord.length > 0
//           ? setData2(data2)
//           : setData2([]);
//       })
//       .catch((err) => console.log(err));
//   };

  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    setSelectedFiles(files);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const handleSelectGroup = (name, selectedGroup) => {
    console.log(name, selectedGroup);
    setSelectedGroup(selectedGroup);
    if (name == "ClassSearch") {
    }
  };

  const removeBodyCss = () => {
    document.body.classList.add("no_padding");
  };

  const getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };

  const togStandard = (kids) => {
    console.log("DONE");
    setModalStandard(!modalStandard);
    setKids(kids);
    removeBodyCss();
  };

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setActiveTab1(tab);
    }
  };

  const onDelete = (id) => {
    // let rows = data2.rows;
    // let confirm = rows.filter((val) => id !== val._id);
    // setData2({ ...data2, rows: confirm });

    // del(`${api.SIGHT}${id}`, true)
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //     setConfirmBoth1(false);
    //     setSuccessDlg(true);
    //     setDynamicTitle("Deleted");
    //     setDynamicDescription("Your file has been deleted.");
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const deleteWord = (sightId, _id) => {
    let filtered = sightWord.filter((val) => val._id !== _id);
    setSightWord(filtered);
    dispatch(deleteSightWords(id))
    if(success == "Successfully sight word deleted"){
      swal("Deleted!", "Your imaginary file has been deleted.", "success");
      setConfirmBoth1(false)
    }
    // del(`${api.SIGHTADD}/${id}`, true, { id: _id })
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //     setConfirmBoth(false);
    //     setSuccessDlg(true);
    //     setDynamicTitle("Deleted");
    //     setDynamicDescription("Your Word has been deleted.");
    //     getSightWords();
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const toggleStock = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMessages = (tab) => {
    if (activeother !== tab) {
      setActiveOther(tab);
    }
  };

  const addSight = (e) => {
    console.log({sight})
    let send = {
        level,
        grade,
        sight: sight.map((val) => {
          return { word: val.word, sound: val.audio.url };
        }),
      };
   
    e.preventDefault();
    if (level !== "" && grade !== "" && sight.length > 0) {
        dispatch(addSightWords(send))
        if(success === "Successfully Subscription add") {
            setLevel("")
            setGrade("")
            setWord("")
            setAudio("")
        }
    //   setLoading(true);
    //   post(api.SIGHTADD, send)
    //     .then(({ data }) => {
    //       setLoading(false);
    //       setIsError(false);
    //       setLevel("");
    //       setGrade("");
    //       setSight([]);
    //       setAudio({});
    //       console.log("===>", data);
    //       getSightWords();
    //       setSuccessDlg(true);
    //       setDynamicTitle("Added");
    //       setDynamicDescription("Your Audio has been added.");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       setLoading(false);
    //     });
    } else {
      setIsError(true);
    }
  };

  const editSight = (e) => {
    e.preventDefault();
    let send = {
            level,
            grade,
          };
    dispatch(editSightWords({id : editId, send}))
    // e.preventDefault();
    // const { level, grade, sight, Class, EditId } = this.state;
    // console.log("<==>", Class.value);
    // if (level !== "" && grade !== "") {
    //   let send = {
    //     level,
    //     grade,
    //   };
    //   console.log(EditId, { send });
    //   put(`sight/${EditId}`, send, true)
    //     .then(({ data }) => {
    //       this.getSightWords();
    //       console.log("===>", data);
    //       this.setState({
    //         level: "",
    //         grade: "",
    //         class: "",
    //         sight: "",
    //         isEdit: false,
    //         EditId: false,
    //       });
    //       // window.reload();
    //     })
    //     .catch((err) => console.log(err));
    // }
  }

  const handleAddWords = (e) => {
    e.preventDefault();
    console.log(sightId)
    if (sightId) {
      let data = {
        word: addWord,
        sound: audio.url,
      }
      dispatch(addWords({id : sightId, data : data}))
    //   put(`${api.SIGHT_WORD_ADD}${sightId}`, {
    //     word: addWord,
    //     sound: audio.url,
    //   })
    //     .then(({ data }) => {
    //       console.log("DONE ADDED ", data);
    //       setSightWord(data.sight);
    //       setAddWord("");
    //       setAudio(false);
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    }
  };

  console.log({Sight})

  const rows = React.useMemo(() => 
  Sight && Sight?.map((val, index) => {
    return {
        action: (
          <>
            <Button
              onClick={() => {
                setSightWord(val.sight && val.sight.length > 0
                    ? val.sight.map((value) => {
                        return { ...value };
                      })
                    : val.sight)
                    setSightId(val._id)
                setShowWords(true)}}
              type="submit"
              color="success"
              className="mr-1"
            >
              Show
            </Button>
            <Button
              onClick={() => {
                setSight(val.sight);
                setLevel(val.level);
                setGrade(val.grade);
                setEditId(val._id);
                setCurrentClass({ label: "", value: val._id });
                setIsEdit(true);
                var elem = document.getElementById("body123");
                if (elem) {
                  elem.scrollIntoView();
                }
              }}
              type="submit"
              color="primary"
              className="mr-1"
            >
              Edit
            </Button>
            <Button
              onClick={(e) => {
                setConfirmBoth1(true);
                setId(val._id);
              }}
              type="submit"
              color="danger"
              className="mr-1"
            >
              Delete
            </Button>
          </>
        ),
        ...val,
      };
                })
, [Sight]
);

  const data = React.useMemo(() => ({
    columns: [
        {
          label: "Level",
          field: "level",
          sort: "asc",
          width: 270,
        },
        {
          label: "Grade",
          field: "grade",
          sort: "asc",
          width: 150,
        },
        {
          label: "Action",
          field: "action",
          sort: "asc",
          width: 270,
        },
      ],
      rows
    }), [rows]);


    console.log({display})

  const notify = () => toast("Audio Added Succusfully");

  const toastify = () => {
    const notify = () => toast(" Audio Added Succussfully");

    return (
      <div>
        <button onClick={notify}>Notify !</button>
        <ToastContainer />
      </div>
    );
  };

  useEffect(() => {
    if (confirmBoth1) {
      swal({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        buttons: {
          cancel: {
            text: "Cancel",
            value: null,
            visible: true,
            className: "btn-success",
            closeModal: true,
          },
          confirm: {
            text: "Confirm",
            value: true,
            visible: true,
            className: "btn-danger",
            closeModal: true
          }
        },
        dangerMode: true,
      })
      .then((willDelete) => {
        if (willDelete) {
          deleteWord(sightId, sightIdVal); // Assuming deleteWord is a function you've defined elsewhere
        } else {
          // Handling cancelation, similar to your old onCancel
          setConfirmBoth1(false);
          setConfirmBoth(false); // Assuming these are state setters you've defined elsewhere
          setErrorDlg(true);
          setDynamicTitle("Cancelled");
          setDynamicDescription("Your imaginary file is safe :)");
          swal("Cancelled", "Your imaginary file is safe :)", "error");
        }
      });      
    }
  }, [confirmBoth1]);


  return (
    <React.Fragment>
      <div id="body123" className="content dasboard-content" style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <ToastContainer />

                {/* {confirmBoth ? (
                  swal({
  title: "Are you sure?",
  text: "You won't be able to revert this!",
  icon: "warning",
  buttons: {
    cancel: {
      text: "Cancel",
      value: null,
      visible: true,
      className: "btn-success",
      closeModal: true,
    },
    confirm: {
      text: "Yes, delete it!",
      value: true,
      visible: true,
      className: "btn-danger",
      closeModal: true
    }
  },
  dangerMode: true,
})
.then((willDelete) => {
  if (willDelete) {
    deleteWord(sightId, sightIdVal); // Assuming deleteWord is a function you've defined elsewhere
    swal("Deleted!", "Your imaginary file has been deleted.", "success");
  } else {
    // Handling cancelation, similar to your old onCancel
    setConfirmBoth(false); // Assuming these are state setters you've defined elsewhere
    setErrorDlg(true);
    setDynamicTitle("Cancelled");
    setDynamicDescription("Your imaginary file is safe :)");
    swal("Cancelled", "Your imaginary file is safe :)", "error");
  }
})
                ) : null} */}
                {successDlg ? (
                  <SweetAlert
                    success
                    title={dynamicTitle}
                    onConfirm={() => setSuccessDlg(false)}
                  >
                    {dynamicDescription}
                  </SweetAlert>
                ) : null}

                {/* {confirmBoth1 ? (
                  swal({
                    title: "Are you sure?",
                    text: "You won't be able to revert this!",
                    icon: "warning",
                    buttons: {
                      cancel: {
                        text: "Cancel",
                        value: null,
                        visible: true,
                        className: "btn-success",
                        closeModal: true,
                      },
                      confirm: {
                        text: "Yes, delete it!",
                        value: true,
                        visible: true,
                        className: "btn-danger",
                        closeModal: true
                      }
                    },
                    dangerMode: true,
                  })
                  .then((willDelete) => {
                    if (willDelete) {
                      deleteWord(sightId, sightIdVal); // Assuming deleteWord is a function you've defined elsewhere
                      swal("Deleted!", "Your imaginary file has been deleted.", "success");
                    } else {
                      // Handling cancelation, similar to your old onCancel
                      setConfirmBoth(false); // Assuming these are state setters you've defined elsewhere
                      setErrorDlg(true);
                      setDynamicTitle("Cancelled");
                      setDynamicDescription("Your imaginary file is safe :)");
                      swal("Cancelled", "Your imaginary file is safe :)", "error");
                    }
                  })
                ) : null} */}
                {successDlg ? (
                  <SweetAlert
                    success
                    title={dynamicTitle}
                    onConfirm={() => setSuccessDlg(false)}
                  >
                    {dynamicDescription}
                  </SweetAlert>
                ) : null}

                {/* <h4 className="page-title">Category</h4>
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      Welcome to Blinkid Category
                    </li>
                  </ol> */}
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {isError && (
                    <Alert
                      color="danger"
                      isOpen={alert14}
                      toggle={() => setAlert14(false)}
                    >
                      <i className="mdi mdi-block-helper mr-2"></i>
                      Credential cannot be Empty!
                    </Alert>
                  )}
                  <h4 className="mt-0 header-title"></h4>
                  <form>
                    {/* <div className="form-group">
                        <label className="control-label">Sight Words</label>
                        <Select
                          value={Class}
                          onChange={(evt) =>
                            this.handleSelectGroup("Class", evt)
                          }
                          options={Classes}
                        />
                      </div> */}
                    <div className="form-group ">
                      <label for="validationCustom01">Level</label>
                      <input
                        type="number"
                        className="form-control"
                        id="validationCustom01"
                        placeholder=""
                        value={level}
                        onChange={(event) => {
                          var count = event.target.value;
                          setLevel(count);
                        }}
                      />
                    </div>
                    <div className="form-group">
                      <label for="validationCustom01">Grade</label>
                      <input
                        type="number"
                        className="form-control"
                        id="validationCustom01"
                        placeholder=""
                        value={grade}
                        onChange={(event) => {
                          var count = event.target.value;
                          setGrade(count);
                        }}
                      />
                    </div>
                    {!isEdit && (
                      <>
                        <div className="form-group">
                          <label for="validationCustom01">Word</label>
                          <div style={{ display: "flex" }}>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              style={{ width: "30%" }}
                              value={word}
                              onChange={(event) => {
                                var count = event.target.value;
                                setWord(count);
                              }}
                            />
                            <Button
                              style={{ marginLeft: 15 }}
                              onClick={(e) => {
                                e.preventDefault();
                                setDisplay(!display);
                              }}
                            >
                              {audio
                                ? "Change Audio"
                                : "Select Audio"}
                            </Button>
                            <span style={{ marginLeft: 10 }}>
                              {audio &&
                                audio.originalname}
                            </span>
                            <Button
                              style={{ marginLeft: 15 }}
                              color="danger"
                              onClick={(e) => {
                                e.preventDefault();
                                if (word !== "" && audio)
                                  setSight([
                                    ...sight,
                                    { word, audio },
                                  ]);
                              }}
                            >
                              +
                            </Button>
                          </div>
                          <Card>
                            <CardBody>
                              <table>
                                <tr>
                                  <th style={{ padding: 15 }}>Word</th>
                                  <th style={{ padding: 15 }}>Song name</th>
                                </tr>
                                {sight.length &&
                                  sight.map((val) => (
                                    <tr>
                                      <td style={{ padding: 15 }}>
                                        {val.word}
                                      </td>
                                      <td style={{ padding: 15 }}>
                                        {val.audio
                                          ? val.audio.originalname
                                          : val.sound}
                                      </td>
                                    </tr>
                                  ))}
                              </table>
                            </CardBody>
                          </Card>
                        </div>
                      </>
                    )}
                    <ImageModal
                      activeTab1={activeTab1}
                      select={(t) => setAudio(t)}
                      setActiveTab={(t) => setActiveTab1(t)}
                      display={display}
                      setDisplay={(t) => setDisplay(t)}
                    />
                    <FormGroup className="mb-0">
  <div>
    <Button
      type="submit"
      color="primary"
      className="mr-1"
      onClick={isEdit ? editSight : addSight}
      disabled={loading}
    >
      {loading ? (
        // <Loader
        //   type="TailSpin"
        //   color="#00BFFF"
        //   height={20}
        //   width={20}
        // />
        "loading"
      ) : (
        ""
      )}
      {isEdit ? "Update" : "Submit"}
    </Button>
  </div>
</FormGroup>
</form>
</CardBody>
</Card>
</Col>
</Row>

<Row>
  <Col>
    <Card>
      <CardBody>
        <Row className="form-group">
          <Col sm="4">
            {/* <div className="form-group">
            <label className="control-label">Class</label>
            <Select
            value={ClassSearch}
            onChange={(evt) =>
            this.handleSelectGroup("ClassSearch", evt)
          }
          options={Classes}
          />
          </div> */}
        </Col>
      </Row>
      {/* <MDBDataTable bordered data={data2} /> */}
    </CardBody>
  </Card>
</Col>
</Row>
</div>
</div>
 <Row>
              <Col>
              
                <Card>
                  <CardBody>
                    <Row className="form-group">
                       
                      <Col sm="4">
                        {/* <div className="form-group">
                          <label className="control-label">Class</label>
                          <Select
                            value={ClassSearch}
                            onChange={(evt) =>
                              this.handleSelectGroup("ClassSearch", evt)
                            }
                            options={Classes}
                          />
                        </div> */}
                      </Col>
                    </Row>
                    <MDBDataTable bordered data={data} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
<Modal
  className="modal-lg"
  isOpen={ShowWords}
  toggle={ModalShowWords}
>
<Row>
              <Col>
  <Card>
    <CardHeader>
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div style={{ height: "100%", fontSize: 22 }}>Add Word</div>
        <Button
          onClick={() => {
           setIsAdd(true)
          }}
        >
          <i className={`fas fa-plus-circle`}></i>
        </Button>
      </div>
    </CardHeader>
    <CardBody
      style={{
        maxHeight: 500,
        overflowY: "auto",
      }}
    >
      {isAdd && (
        <>
          <label for="validationCustom01">Word</label>
          <div style={{ display: "flex" }}>
            <input
              type="text"
              className="form-control"
              id="validationCustom01"
              placeholder=""
              style={{ width: 100 }}
              value={addWord}
              onChange={(event) => {
                var count = event.target.value;
               setAddWord(count)
              }}
            />
            <Button
              style={{ marginLeft: 15 }}
              onClick={(e) => {
                e.preventDefault();
                setDisplay(true);
              }}
            >
              {audio ? "Change Audio" : "Select Audio"}
            </Button>
            <span style={{ marginLeft: 10 }}>
              {audio &&
              audio.originalname &&
              audio.originalname.length > 15
                ? audio.originalname.substring(0, 15 - 3) +
                  "..."
                : audio.originalname}
            </span>
            <Button
              style={{ marginLeft: 15 }}
              color="danger"
              disabled={addWord.length > 0 ? false : true}
              onClick={handleAddWords}
            >
              {addWord.length > 0 ? (
                <i className={`mdi mdi-check-bold`}></i>
              ) : (
                <i className={`ti-close`}></i>
              )}
            </Button>
          </div>
        </>
      )}
      <table>
        <tr>
          <th style={{ padding: 30 }}>Word</th>
          <th style={{ padding: 30 }}>Audio</th>
        </tr>
        {sightWord &&
          sightWord.map((val) => (
           
            <tr>
              <td style={{ padding: 30 }}>{val.word}</td>
              <td style={{ padding: 30 }}>
                {" "}
                <ReactAudioPlayer src={val.sound} controls />
              </td>
              <td style={{ padding: 30 }}>
                <i
                  onClick={(e) =>
                    {setConfirmBoth(true)
                    setSightIdVal(val._id)}
                 
                  }
                  style={{
                    cursor: "pointer",
                    fontSize: 24,
                  }}
                  className={`mdi mdi-trash-can-outline text-danger`}
                ></i>
              </td>
            </tr>
          ))}
      </table>
    </CardBody>
  </Card>
  </Col>
 
            </Row>
 
</Modal>
      </React.Fragment>
    );
                        }

export default SightWords