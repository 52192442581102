import React, { useState, useEffect,useMemo } from "react";
import { Row, Col, Card, CardBody, CardHeader, Modal, Button, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import moment from "moment";
import { useDispatch,useSelector } from 'react-redux'
import { deleteUser, getAllUsers } from "../../redux/slices/user.slice";

import { ToastContainer, toast } from "react-toastify";
// import { Link } from "react-router-dom";
// import { api } from "../../../helpers/apiLinks";
// import { del, get, put } from "../../../helpers/apiCalls";
import { MDBDataTable } from "mdbreact";
import { CSVLink, CSVDownload } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import { Link } from "react-router-dom";


const User = () => {
    const dispatch = useDispatch();

    const {users,totalUsers,schoolCount,kidsCount,usersCount} = useSelector(state => state.user)

    const [activeTab, setActiveTab] = useState("1");
    const [activeother, setActiveother] = useState("1");
    const [startDate, setStartDate] = useState(new Date());
    const [visible, setVisible] = useState(false);
    const [modal_standard, setModal_standard] = useState(false);
    const [data, setData] = useState([]);
    const [kids, setKids] = useState([]);
    const [confirm_both, setConfirm_both] = useState(false);
    const [id,setId] = useState('')
    const [success_dlg, setSuccess_dlg] = useState(false);
    const [dynamic_title, setDynamic_title] = useState("");
    const [dynamic_description, setDynamic_description] = useState("");
    const [paymentDetail, setPaymentDetail] = useState()

    const data2 = useMemo(() => ({
        columns: [
          { label: "Email", field: "email", sort: "asc", width: 150 },
          { label: "Verified", field: "isVerified", sort: "asc", width: 270 },
          { label: "Paid", field: "isPaid", sort: "asc", width: 270 },
          { label: "Action", field: "action", sort: "asc", width: 270 },
        ],
        rows: users?.map((val, index) => ({
          ...val,
          email: `${val.email} \n On ${moment(val.createdAt).format("DD MMM, YYYY")}`,
          isVerified: (
            <div>
              <i
                className={`mdi mdi-checkbox-blank-circle mr-1 ${val.isVerified ? "text-success" : "text-warning"}`}
              ></i>
              {val.isVerified ? "Verified" : "Pending"}
            </div>
          ),
          isPaid: (
            <div>
              <i
                className={`mdi mdi-checkbox-blank-circle mr-1 ${val.isPaid ? "text-success" : "text-warning"}`}
              ></i>
            </div>
          ),
          action: (
            <span>
              {/* {val.kids && val.kids.length > 0 && ( */}
              {/* <Link
                onClick={() =>
                  this.tog_standard(val.kids ? val.kids : [])
                }
                to="#"
                className="btn btn-primary btn-sm"
              >
                Show Kids
              </Link> */}
              {/* )} */}

              {val.paymentDetail && val.paymentDetail.length > 0 && (
                <Link
                  style={{ marginLeft: 15 }}
                  // onClick={() => this.onDelete(val._id, index)}
                  onClick={() =>
                    this.setState({
                      paymentDetail: {
                        columns: [
                          {
                            label: "Amount",
                            field: "amount",
                            sort: "asc",
                            width: 270,
                          },
                          {
                            label: "Interval",
                            field: "interval",
                            sort: "asc",
                            width: 270,
                          },
                          {
                            label: "Start Date",
                            field: "start_date",
                            sort: "asc",
                            width: 270,
                          },
                          {
                            label: "End Date",
                            field: "end_date",
                            sort: "asc",
                            width: 270,
                          },
                          {
                            label: "Status",
                            field: "status",
                            sort: "asc",
                            width: 270,
                          },
                        ],
                        rows: val.paymentDetail.map((value, index) => {
                          return {
                            ...value,

                            amount: value && value.amount + " $",
                            status: (
                              <div>
                                <i
                                  className={`mdi mdi-checkbox-blank-circle mr-1'
                             ${
                               value && value.status
                                 ? "text-success"
                                 : " text-warning"
                             }
                             `}
                                ></i>{" "}
                                {value &&
                                  value.status &&
                                  value.status.toUpperCase()}
                              </div>
                            ),
                            start_date: (
                              <div>
                                {value &&
                                  moment(
                                    new Date(
                                      value.start_date * 1000
                                    ).toISOString()
                                  ).format("MMM Do YYYY")}
                              </div>
                            ),
                            end_date: (
                              <div>
                                {value &&
                                  this.calculateEndData(
                                    new Date(
                                      value.start_date * 1000
                                    ).toISOString(),
                                    value && value.interval
                                  ).format("MMM Do YYYY")}
                              </div>
                            ),
                          };
                        }),
                      },
                      modal_standard: true,
                    })
                  }
                  to="#"
                  className="btn btn-primary btn-sm"
                >
                  Payment History
                </Link>
              )}
              <Link
                style={{ marginLeft: 15 }}
                onClick={(e) =>{
                    setConfirm_both(true)
                    setId(val._id)}
                }
                to="#"
                className="btn btn-danger btn-sm"
              >
                Delete
              </Link>
              <select
                    onChange={(e) => {
                    //   put(`user/${val._id}`, { isPaid: e.target.value }, true)
                    //   .then(({ data }) => {
                    //     console.log("===>", data);
                    //     this.notify("Updated");
                    //     this.getAllUser();
                    //   })
                    //   .catch((err) => {
                    //     console.log(err);
                    //   });
                    }}
                    className="form-control"
                    value={val.isPaid}
                  >
                    <option value={"true"}>True</option>
                    <option value={"false"}>False</option>
                  </select>
            </span>
          ),
        })),
      }), [users]); // Dependency array ensures this memoizes the result until `data.user` changes
    


    useEffect(() => {
        dispatch(getAllUsers())
    }, [])

    //   notify = (msg) => toast("Category " + msg + " Succusfully");


    //   removeBodyCss() {
    //     document.body.classList.add("no_padding");
    //   }

    //   tog_standard(kids) {
    //     console.log("DONE");
    //     this.setState({
    //       modal_standard: !modal_standard,
    //       kids,
    //     });
    //     this.removeBodyCss();
    //   }

     const onDelete = (id) => {
        // this.setState({
        //   data: { ...this.state.data, rows: confirm },
        // });
        
        dispatch(deleteUser(id))
        setConfirm_both(false);
        setSuccess_dlg(true);
        setDynamic_title("Deleted User");
        setDynamic_description("Deleted User");

        // del(`${api.USERS}/${id}`, true)
        //   .then(({ data }) => {
        //     console.log("DELETE SUCCESS", data);
        //     this.setState({
        //       confirm_both: false,
        //       success_dlg: true,
        //       dynamic_title: "Deleted",
        //       dynamic_description: "Your file has been deleted.",
        //     });
        //   })
        //   .catch((err) => {
        //     console.log(err);
        //   });
      };

    //   calculateEndData = (date, check) => {
    //     let datE = moment(date);
    //     let b = datE.add("1", check);
    //     return b;
    //   };

    //   getAllUser = () => {
    //     get(api.USERS, true)
    //       .then(({ data }) => {
    //         if (data.success) {
    //           let data2 = {
    //             columns: [
    //               {
    //                 label: "Email",
    //                 field: "email",
    //                 sort: "asc",
    //                 width: 150,
    //               },
    //               {
    //                 label: "Verified",
    //                 field: "isVerified",
    //                 sort: "asc",
    //                 width: 270,
    //               },
    //               {
    //                 label: "Paid",
    //                 field: "isPaid",
    //                 sort: "asc",
    //                 width: 270,
    //               },
    //               {
    //                 label: "Action",
    //                 field: "action",
    //                 sort: "asc",
    //                 width: 270,
    //               },
    //             ],
    //             rows: data.user.map((val, index) => {
    //               return {
    //                 ...val,
    //                 email: `${val.email} ${"\n"}
    //                    On ${moment(val.createdAt).format("DD MMM, YYYY")}

    //                    `,
    //                 isVerified: (
    //                   <div>
    //                     <i
    //                       className={`mdi mdi-checkbox-blank-circle mr-1'
    //                  ${val.isVerified ? " text-success" : " text-warning"}
    //                  `}
    //                     ></i>
    //                     {val.isVerified ? "Verified" : "Pending"}
    //                   </div>
    //                 ),
    //                 isPaid: (
    //                   <div>
    //                     <i
    //                       className={`mdi mdi-checkbox-blank-circle mr-1'
    //                  ${val.isPaid ? "text-success" : " text-warning"}
    //                  `}
    //                     ></i>
    //                   </div>
    //                 ),

    //                 action: (
    //                   <span>
    //                     {/* {val.kids && val.kids.length > 0 && ( */}
    //                     {/* <Link
    //                       onClick={() =>
    //                         this.tog_standard(val.kids ? val.kids : [])
    //                       }
    //                       to="#"
    //                       className="btn btn-primary btn-sm"
    //                     >
    //                       Show Kids
    //                     </Link> */}
    //                     {/* )} */}

    //                     {val.paymentDetail && val.paymentDetail.length > 0 && (
    //                       <Link
    //                         style={{ marginLeft: 15 }}
    //                         // onClick={() => this.onDelete(val._id, index)}
    //                         onClick={() =>
    //                           this.setState({
    //                             paymentDetail: {
    //                               columns: [
    //                                 {
    //                                   label: "Amount",
    //                                   field: "amount",
    //                                   sort: "asc",
    //                                   width: 270,
    //                                 },
    //                                 {
    //                                   label: "Interval",
    //                                   field: "interval",
    //                                   sort: "asc",
    //                                   width: 270,
    //                                 },
    //                                 {
    //                                   label: "Start Date",
    //                                   field: "start_date",
    //                                   sort: "asc",
    //                                   width: 270,
    //                                 },
    //                                 {
    //                                   label: "End Date",
    //                                   field: "end_date",
    //                                   sort: "asc",
    //                                   width: 270,
    //                                 },
    //                                 {
    //                                   label: "Status",
    //                                   field: "status",
    //                                   sort: "asc",
    //                                   width: 270,
    //                                 },
    //                               ],
    //                               rows: val.paymentDetail.map((value, index) => {
    //                                 return {
    //                                   ...value,

    //                                   amount: value && value.amount + " $",
    //                                   status: (
    //                                     <div>
    //                                       <i
    //                                         className={`mdi mdi-checkbox-blank-circle mr-1'
    //                                    ${
    //                                      value && value.status
    //                                        ? "text-success"
    //                                        : " text-warning"
    //                                    }
    //                                    `}
    //                                       ></i>{" "}
    //                                       {value &&
    //                                         value.status &&
    //                                         value.status.toUpperCase()}
    //                                     </div>
    //                                   ),
    //                                   start_date: (
    //                                     <div>
    //                                       {value &&
    //                                         moment(
    //                                           new Date(
    //                                             value.start_date * 1000
    //                                           ).toISOString()
    //                                         ).format("MMM Do YYYY")}
    //                                     </div>
    //                                   ),
    //                                   end_date: (
    //                                     <div>
    //                                       {value &&
    //                                         this.calculateEndData(
    //                                           new Date(
    //                                             value.start_date * 1000
    //                                           ).toISOString(),
    //                                           value && value.interval
    //                                         ).format("MMM Do YYYY")}
    //                                     </div>
    //                                   ),
    //                                 };
    //                               }),
    //                             },
    //                             modal_standard: true,
    //                           })
    //                         }
    //                         to="#"
    //                         className="btn btn-primary btn-sm"
    //                       >
    //                         Payment History
    //                       </Link>
    //                     )}
    //                     <Link
    //                       style={{ marginLeft: 15 }}
    //                       onClick={(e) =>
    //                         this.setState({ confirm_both: true, id: val._id })
    //                       }
    //                       to="#"
    //                       className="btn btn-danger btn-sm"
    //                     >
    //                       Delete
    //                     </Link>
    //                     <select
    //                           onChange={(e) => {
    //                             put(`user/${val._id}`, { isPaid: e.target.value }, true)
    //                             .then(({ data }) => {
    //                               console.log("===>", data);
    //                               this.notify("Updated");
    //                               this.getAllUser();
    //                             })
    //                             .catch((err) => {
    //                               console.log(err);
    //                             });
    //                           }}
    //                           className="form-control"
    //                           value={val.isPaid}
    //                         >
    //                           <option value={"true"}>True</option>
    //                           <option value={"false"}>False</option>
    //                         </select>
    //                   </span>
    //                 ),
    //               };
    //             }),
    //           };
    //           this.setState({
    //             users: data.user,
    //             totalUsers: data.totalUsers,
    //             schoolCount: data.schoolCount,
    //             kidsCount: data.kidsCount,
    //             usersCount: data.usersCount,
    //             data: data2,
    //             users: data.user,
    //           });
    //         } else {
    //           console.log(data);
    //         }
    //         console.log("DATA IS HERE", data);
    //       })
    //       .catch((err) => {
    //         console.log("SOME ERROR", err);
    //       });
    //   };


    const toggle = () => setModal_standard(!modal_standard);

    return (
        <React.Fragment>
            <div className="content dasboard-content" style={{paddingTop : '80px'}}>
                <div className="container-fluid">
                    <div className="page-title-box">
                    <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Dashboard</h4>

                  {confirm_both ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmBtnBsStyle="danger"
                      cancelBtnBsStyle="success"
                      onConfirm={() => {
                        onDelete(id);
                      }}
                      onCancel={() =>
                        this.setState({
                          confirm_both: false,
                          error_dlg: true,
                          dynamic_title: "Cancelled",
                          dynamic_description: "Your imaginary file is safe :)",
                        })
                      }
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}

                  {success_dlg ? (
                    <SweetAlert
                      success
                      title={dynamic_title}
                      onConfirm={() => setSuccess_dlg(false)}
                    >
                      {dynamic_description}
                    </SweetAlert>
                  ) : null}

                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      Welcome to Blinkid Dashboard
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <Row>
              <Col lg="3">
                <Card className="mini-stat bg-pattern">
                  <CardBody className="mini-stat-img">
                    <div className="mini-stat-icon">
                      <i className="dripicons-broadcast bg-soft-primary text-primary float-right h4"></i>
                    </div>
                    <h6 className="text-uppercase mb-3 mt-0">Total Users</h6>
                    <h5 className="mb-3">{totalUsers}</h5>
                  </CardBody>
                </Card>
              </Col>
              <Modal
                className="modal-lg"
                isOpen={modal_standard}
                toggle={toggle}
              >
                <div className="modal-header modal-lg">
                  <h5 className="modal-title mt-0" id="myModalLabel">
                    Modal Heading
                  </h5>
                  <button
                    type="button"
                    onClick={() => setModal_standard(false)}
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <h5>Payment Details</h5>
                  <Card>
                    <CardBody>
                      <MDBDataTable bordered data={paymentDetail} />
                    </CardBody>
                  </Card>
                </div>
              </Modal>
              <Col lg="3">
                <Card className="mini-stat bg-pattern">
                  <CardBody className="mini-stat-img">
                    <div className="mini-stat-icon">
                      <i className="dripicons-box bg-soft-primary text-primary float-right h4"></i>
                    </div>
                    <h6 className="text-uppercase mb-3 mt-0">Users Count</h6>
                    <h5 className="mb-3">{usersCount}</h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="mini-stat bg-pattern">
                  <CardBody className="mini-stat-img">
                    <div className="mini-stat-icon">
                      <i className="dripicons-tags bg-soft-primary text-primary float-right h4"></i>
                    </div>
                    <h6 className="text-uppercase mb-3 mt-0">School Counts</h6>
                    <h5 className="mb-3">{schoolCount}</h5>
                  </CardBody>
                </Card>
              </Col>
              <Col lg="3">
                <Card className="mini-stat bg-pattern">
                  <CardBody className="mini-stat-img">
                    <div className="mini-stat-icon">
                      <i className="dripicons-tags bg-soft-primary text-primary float-right h4"></i>
                    </div>
                    <h6 className="text-uppercase mb-3 mt-0">Kids Count</h6>
                    <h5 className="mb-3">{kidsCount}</h5>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <Button className="btn">
                      <CSVLink
                        data={
                          users && users.length > 0
                            ? users.map((val) => {
                                return {
                                  // Name: val.name,
                                  Email: val.email
                                    ? val.email
                                    : "No There is no Parent email Sorry",
                                  Verified: val.isVerified,
                                  Paid: val.isPaid,
                                  "Created At": moment(val.createdAt).format(
                                    "DD MMM, YYYY"
                                  ),
                                };
                              })
                            : []
                        }
                      >
                        Download
                      </CSVLink>
                    </Button>
                  </CardHeader>
                  <CardBody>
                    <MDBDataTable bordered data={data2} />
                  </CardBody>
                </Card>
              </Col>
            </Row>
                    {/* <Row>
              <Col xl="12">
                <Card>
                  <CardBody>
                    <h4 className="mt-0 header-title mb-4">
                      Latest Transactions
                    </h4>
                    <div className="table-responsive">
                      <table className="table mb-0">
                        <tbody>
                          {users &&
                            users.map((val) => (
                              <tr>
                                <td>
                                  {val.email}
                                  <p className="m-0 text-muted">
                                    On{" "}
                                    {moment(val.created).format("DD MMM, YYYY")}
                                  </p>
                                </td>
                                <td>
                                  <i
                                    className={`mdi mdi-checkbox-blank-circle mr-1
                                   ${
                                     val.isVerified
                                       ? " text-success"
                                       : " text-warning"
                                   }
                                   `}
                                  ></i>{" "}
                                  {val.isVerified ? "Verified" : "Pending"}
                                </td>
                                <td>
                                  <div>
                                    <Link
                                      to="#"
                                      className="btn btn-primary btn-sm"
                                    >
                                      Edit
                                    </Link>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row> */}
                </div>
            </div>
        </React.Fragment>
    );
}


export default User
