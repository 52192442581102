// userSlice.ts

import { createSlice, PayloadAction, createAsyncThunk } from '@reduxjs/toolkit';
import api from '../../utils/api';
import axios from 'axios';
import AddVideo from 'views/AddVideo';
// import { login } from './auth.actions';



const initialState = {
    isLoading: false,
    success: null,
    error: null,
    users: null,
    files : null,
    videos : [],
    audios : [],
    images : [],
    kidsCount: 0,
    schoolCount: 0,
    totalUsers: 0,
    usersCount: 0,
    category: [],
    categories: {},
    subject: [],
    class: [],
    sight: [],
    subscription: [],
    phonics: [],
};


export const getAllUsers = createAsyncThunk(
    'user/getAllUsers',
    async () => {
        const response= await api.get('/user')
        return response.data
    }
)

export const deleteUser = createAsyncThunk(
    'user/deleteUser',
    async (id) => {
        const response= await api.delete(`/user/${id}`)
        return {data:response.data,id}
    }
)

export const getAllCategories = createAsyncThunk(
    'user/getAllCategories',
    async () => {
        const response= await api.get('/category')
        return response.data
    }
)

export const getAllCategory = createAsyncThunk(
    'user/getAllCategory',
    async () => {
        const response= await api.get('/categories')
        return response.data
    }
)

export const uploadAssets = createAsyncThunk(
    'user/uploadAssets',
    async (file) => {
        const response= await api.post(`/upload/file`, file)
        return response.data
    }
)

export const getImages = createAsyncThunk(
    'user/getImages',
    async (file) => {
        const response= await api.get(`upload/images`)
        return response.data
    }
)

export const getVideos = createAsyncThunk(
    'user/getVideos',
    async (file) => {
        const response= await api.get(`upload/videos`)
        return response.data
    }
)

export const getAllVideos = createAsyncThunk(
    'user/getAllVideos',
    async (file) => {
        const response= await api.get(`videos`)
        return response.data
    }
)

export const getAudios = createAsyncThunk(
    'user/getAudios',
    async (file) => {
        const response= await api.get(`upload/audios`)
        return response.data
    }
)

export const addCategories = createAsyncThunk(
    'user/addCategories',
    async (data) => {
        const response= await api.post(`category/add`, data)
        return response.data
    }
)

export const editCategory = createAsyncThunk(
    'user/editCategory',
    async (data) => {
        const response= await api.put(`category/${data.categoryId}`, data)
        return response.data
    }
)

export const deleteCategory = createAsyncThunk(
    'user/deleteCategory',
    async (id) => {
        const response= await api.delete(`category/${id}`)
        return {data: response.data, id}
    }
)

export const getAllSubjects = createAsyncThunk(
    'user/getAllSubjects',
    async () => {
        const response= await api.get(`subject`)
        return response.data
    }
)


export const addSubject = createAsyncThunk(
    'user/addSubject',
    async (name) => {
        const response= await api.post(`subject`, name)
        return response.data
    }
)

export const editSubject = createAsyncThunk(
    'user/editSubject',
    async (data) => {
        const response= await api.put(`subject/${data.id}`, data)
        return {data : response.data, id : data.id}
    }
)

export const deleteSubject = createAsyncThunk(
    'user/deleteSubject',
    async (id) => {
        const response= await api.delete(`subject/${id}`)
        return {data : response.data, id}
    }
)

export const getAllClass = createAsyncThunk(
    'user/getAllClass',
    async () => {
        const response= await api.get(`class`)
        return response.data
    }
)

export const addClass = createAsyncThunk(
    'user/addClass',
    async (name) => {
        const response= await api.post(`class`, name)
        return response.data
    }
)

export const editClass = createAsyncThunk(
    'user/editClass',
    async (data) => {
        const response= await api.put(`class/${data.id}`, data)
        return {data : response.data, id : data.id}
    }
)

export const deleteClass = createAsyncThunk(
    'user/deleteClass',
    async (id) => {
        const response= await api.delete(`class/${id}`)
        return {data : response.data, id}
    }
)

export const getAllSightWords = createAsyncThunk(
    'user/getAllSightWords',
    async () => {
        const response= await api.get('sights/')
        return response.data
    }
)

export const addSightWords = createAsyncThunk(
    'user/addSightWords',
    async (name) => {
        const response= await api.post(`sight/add`, name)
        return response.data
    }
)

export const editSightWords = createAsyncThunk(
    'user/editSightWords',
    async (data) => {
        const response= await api.put(`sight/${data.id}`, data.send)
        return {data : response.data, id : data.id}
    }
)

export const deleteSightWords = createAsyncThunk(
    'user/deleteSightWords',
    async (id) => {
        const response= await api.delete(`sight/${id}`)
        return {data : response.data, id}
    }
)

export const getAllSubscriptions = createAsyncThunk(
    'user/getAllSubscriptions',
    async () => {
        const response= await api.get(`amount/`)
        return response.data
    }
)

export const addPhonic = createAsyncThunk(
    'user/addPhonic',
    async (data) => {
        const response= await api.post(`phonics/add/`, data)
        return response.data
    }
)


export const getPhonics = createAsyncThunk(
    'user/getPhonics',
    async () => {
        const response= await api.get(`phonics/`)
        return response.data
    }
)

export const editPhonics = createAsyncThunk(
    'user/editPhonics',
    async (data) => {
        const response= await api.put(`phonics/${data.id}`, data.data)
        return {data : response.data, id : data.id}
    }
)

export const deletePhonics = createAsyncThunk(
    'user/deletePhonics',
    async (id) => {
        const response= await api.delete(`phonics/${id}`)
        return {data : response.data, id}
    }
)

export const addQuestion = createAsyncThunk(
    'user/addQuestion',
    async (data) => {
        const response= await api.post(`question/add`, data)
        return response.data
    }
)

export const addVideo = createAsyncThunk(
    'user/addVideo',
    async (data) => {
        const response= await api.post(`video/add`, data)
        return response.data
    }
)

export const editSubscription = createAsyncThunk(
    'user/editSubscription',
    async (data) => {
        console.log(data)
        const response= await api.put(`sight/${data.id}`, data)
        return {data : response.data, id : data.id}
    }
)

export const addWords = createAsyncThunk(
    'user/addWords',
    async (data) => {
        const response= await api.put(`sight/add/${data.id}`, data.data)
        return response.data
    }
)


// Continuing in userSlice.ts

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getAllUsers.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllUsers.fulfilled, (state, action) => {
                state.users = action.payload.users
                state.kidsCount = action.payload.kidsCount
                state.schoolCount = action.payload.schoolCount
                state.totalUsers = action.payload.totalUsers
                state.usersCount = action.payload.usersCount
                state.isLoading = false;
                
            })
            .addCase(getAllUsers.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllCategories.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllCategories.fulfilled, (state, action) => {
            
                state.category = action.payload.category
                state.isLoading = false;
                
            })
            .addCase(getAllCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(deleteUser.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                const filterUser = state.users.filter(user => user._id !== action.payload.id)
                state.users = filterUser
                state.isLoading = false;
                
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(uploadAssets.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(uploadAssets.fulfilled, (state, action) => {
               
                state.files = action.payload
                state.isLoading = false;
                
            })
            .addCase(uploadAssets.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getImages.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getImages.fulfilled, (state, action) => {
               
                state.images = action.payload.images
                state.isLoading = false;
                
            })
            .addCase(getImages.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getVideos.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getVideos.fulfilled, (state, action) => {
               
                state.videos = action.payload.videos
                state.isLoading = false;
                
            })
            .addCase(getVideos.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAudios.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAudios.fulfilled, (state, action) => {
              
                state.audios = action.payload.audios
                state.isLoading = false;
                
            })
            .addCase(getAudios.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addCategories.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addCategories.fulfilled, (state, action) => {
                state.category = [...state.category, action.payload.category];
                state.success = "successfully category add";
                state.isLoading = false;
              })
            .addCase(addCategories.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(editCategory.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editCategory.fulfilled, (state, action) => {
                const filteredCategory = state.category.filter(cat => cat._id !== action.payload.category._id);
                state.category = [...filteredCategory, action.payload.category];
                state.success = 'successfully category edit';
                state.isLoading = false;
              })
            .addCase(editCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(deleteCategory.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteCategory.fulfilled, (state, action) => {
                const filteredCategory = state.category.filter(cat => cat._id !== action.payload.id);
                state.category = filteredCategory;
                state.isLoading = false;
              })
            .addCase(deleteCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            
            .addCase(getAllSubjects.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllSubjects.fulfilled, (state, action) => {
                state.subject = action.payload.subjects
                state.isLoading = false;
                
            })
            .addCase(getAllSubjects.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addSubject.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addSubject.fulfilled, (state, action) => {
                state.subject = [...state.subject, action.payload.data];
                state.success = "Subject successfully added";
                state.isLoading = false;
            })
            .addCase(addSubject.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(editSubject.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editSubject.fulfilled, (state, action) => {
                const updatedSubjects = state.subject.map(subject => {
                    if (subject._id === action.payload.id) {
                        return action.payload.data.result;
                    }
                    return subject;
                });
                state.subject = updatedSubjects;
                state.success = 'Subject successfully edited';
                state.isLoading = false;
            })
            .addCase(editSubject.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(deleteSubject.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSubject.fulfilled, (state, action) => {
                state.subject = state.subject.filter(subject => subject._id !== action.payload.id);
                state.isLoading = false;
            })
            .addCase(deleteSubject.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllClass.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllClass.fulfilled, (state, action) => {
                state.class = action.payload.classes
                state.isLoading = false;
                
            })
            .addCase(getAllClass.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addClass.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addClass.fulfilled, (state, action) => {
                state.class = [...state.class, action.payload.data];
                state.success = "Class successfully added";
                state.isLoading = false;
            })
            .addCase(addClass.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(editClass.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editClass.fulfilled, (state, action) => {
                const updatedClass = state.class.map(item => {
                    if (item._id === action.payload.id) {
                        return action.payload.data.result;
                    }
                    return item;
                });
                state.class = updatedClass;
                state.success = 'Class successfully edited';
                state.isLoading = false;
            })            
            .addCase(editClass.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(deleteClass.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteClass.fulfilled, (state, action) => {
                state.class = state.class.filter(item => item._id !== action.payload.id);
                state.isLoading = false;
            })            
            .addCase(deleteClass.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllSightWords.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllSightWords.fulfilled, (state, action) => {
                state.sight = action.payload.sightWord
                state.isLoading = false;
                
            })
            .addCase(getAllSightWords.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addSightWords.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addSightWords.fulfilled, (state, action) => {
                // console.log(action.payload)
                state.sight = [...state.sight, action.payload.sightWord];
                state.success = "Successfully Subscription add"
                state.isLoading = false;
            })
            .addCase(addSightWords.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(editSightWords.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editSightWords.fulfilled, (state, action) => {
           
                const updatedSight = state.sight.map(item => {
                    if (item._id === action.payload.data.sight._id) {
                        return action.payload.data.sight;
                    }
                    return item;
                });
                state.sight = updatedSight;
                state.success = 'Sight successfully edited';
                state.isLoading = false;
            })            
            .addCase(editSightWords.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(deleteSightWords.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deleteSightWords.fulfilled, (state, action) => {
                console.log('is deleted ==>', action.payload)
                state.sight = state.sight.filter(item => item._id !== action.payload.id);
                state.success = "Successfully sight word deleted"
                state.isLoading = false;
            })            
            .addCase(deleteSightWords.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllSubscriptions.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllSubscriptions.fulfilled, (state, action) => {
                state.subscription = action.payload.amount
                state.isLoading = false;
            })
            .addCase(getAllSubscriptions.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addPhonic.fulfilled, (state, action) => {
                state.phonics = [...state.phonics, action.payload.phonic];
                state.success = "Successfully Phonic add"
                state.isLoading = false;
            })
            .addCase(addPhonic.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addPhonic.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getPhonics.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getPhonics.fulfilled, (state, action) => {
                state.phonics = action.payload.phonics
                state.isLoading = false;
            })
            .addCase(getPhonics.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addQuestion.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deletePhonics.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(deletePhonics.fulfilled, (state, action) => {
                console.log('is deleted ==>', action.payload)
                state.phonics = state.phonics.filter(item => item._id !== action.payload.id);
                state.success = "Successfully phonics word deleted"
                state.isLoading = false;
            })            
            .addCase(deletePhonics.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(editPhonics.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(editPhonics.fulfilled, (state, action) => {
                const updatedPhonics = state.phonics.map(item => {
                    if (item._id === action.payload.data.category._id) {
                        return action.payload.data.category;
                    }
                    return item;
                });
                state.phonics = updatedPhonics;
                state.success = 'Sight successfully edited';
                state.isLoading = false;
            })            
            .addCase(editPhonics.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(addQuestion.fulfilled, (state, action) => {
                // console.log('===>', action.payload)
                // state.phonics = action.payload.phonics
                state.isLoading = false;
            })
            .addCase(addQuestion.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllCategory.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllCategory.fulfilled, (state, action) => {
                if (action.payload.songs && action.payload.songs.length) {
                  state.categories = {
                    ...state.categories,
                    label: "Types",
                    options: [
                      { label: "Songs", value: action.payload.songs },
                      { label: "Phonics", value: action.payload.phonics },
                      { label: "Specials", value: action.payload.specials },
                      { label: "classic", value: action.payload.classic },
                    ],
                  };
                }
                state.isLoading = false;
              })
              .addCase(getAllCategory.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
              })
              .addCase(addVideo.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(addVideo.fulfilled, (state, action) => {
                // console.log('===>', action.payload)
                // state.phonics = action.payload.phonics
                state.success = 'Video successfully edited';
                state.isLoading = false;

            })
            .addCase(addVideo.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
            .addCase(getAllVideos.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getAllVideos.fulfilled, (state, action) => {
                state.success = 'Video successfully get';
                state.isLoading = false;
                console.log(action.payload)

            })
            .addCase(getAllVideos.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.error.message || 'Failed to fetch';
            })
    }
});

export const {  } = userSlice.actions;
export default userSlice.reducer;


