import React, { useState, useEffect, useMemo } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Nav,
  NavItem,
  TabPane,
  TabContent,
  NavLink,
  CardHeader,
  Button,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
// import { withRouter } from "react-router-dom";
// import { connect } from "react-redux";
import classnames from "classnames";
// import moment from "moment";
// import { api } from "../../../helpers/apiLinks";
import { ToastContainer, toast } from "react-toastify";
// import { del, get, post, put } from "../../../helpers/apiCalls";
import { AvForm, AvField } from "availity-reactstrap-validation";
import {TailSpin} from "react-loader-spinner";
import { MDBDataTable } from "mdbreact";
import BootstrapTable from "react-bootstrap-table-next";
import cellEditFactory from "react-bootstrap-table2-editor";
import { useDispatch, useSelector } from "react-redux";
import { addSubject, deleteSubject, editSubject, getAllSubjects } from "../../redux/slices/user.slice";

const columns = [
  {
    dataField: "name",
    text: "Subject Name",
  },

  {
    dataField: "action",
    text: "Delete Subject",
  },
];

const Subject = () => {
    const {subject,  success} = useSelector(state => state.user)
    const dispatch = useDispatch()
  const [activeTab1, setActiveTab1] = useState("5");
  const [name, setName] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [subjectId, setSubjectId] = useState("");
  const [loading, setLoading] = useState(false);
  const [err, setErr] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  useEffect(() => {
    dispatch(getAllSubjects())
  }, []);

  const notify = (msg) => toast(`Subject ${msg ? msg : "Added"} Successfully`);


  const handleSubjectEdit = () => {
    let id = subjectId;
    if (name !== "") {
      let newData = data.map((val) => {
        if (val._id !== id) {
          return val;
        }
        return { ...val, name: name };
      });
    dispatch(editSubject({id, name}));
    if(success === "Subject successfully edited"){
        setActiveTab1("6")
    }
    }
  };

  const addClass = () => {
    if (name === "") {
      setErr(true);
      setErrMsg("Please Fill out the Fields!");
    } else {
      dispatch(addSubject({name}))
      if(success === "Subject successfully added"){
        setName("");
      }
    }
  };

  const toggle1 = (cur) => {
    setActiveTab1(cur);
  };

  const columns = [
    {
      dataField: "name",
      text: "Subject Name",
    },
  
    {
      dataField: "action",
      text: "Delete Subject",
    },
  ];
  
  const handleEditClick = (val) => {
    setSubjectId(val._id);
    setIsEdit(true);
    setActiveTab1("5");
    setName(val.name);
  };

  const deleteClass = (id) => {
    dispatch(deleteSubject(id))
  };

  const data = useMemo(() => {
    return (
      subject &&
      subject.map((val, index) => {
        return {
          ...val,
          action: (
            <>
              <Button
                onClick={() =>
                  handleEditClick(val)
                }
                className="btn btn-primary"
              >
                {" "}
                Edit{" "}
              </Button>
              <Button
                onClick={() => deleteClass(val._id)}
                className="btn btn-danger"
              >
                {" "}
                Delete{" "}
              </Button>
            </>
          ),
        };
      })
    );
  }, [subject]);

  return (
    <React.Fragment>
      <div className="content dasboard-content" style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Subjects</h4>
                <ToastContainer />
              </div>
            </div>
          </div>
          <Nav pills className="navtab-bg nav-justified">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab1 === "5",
                })}
                onClick={() => {
                  toggle1("5");
                }}
              >
                Add Subject
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab1 === "6",
                })}
                onClick={() => {
                  toggle1("6");
                }}
              >
                Show Subject
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab1}>
            <TabPane tabId="5" className="p-3">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <h4 className="mt-0 header-title">Add Subject</h4>
                      <AvForm>
                        <AvField
                          name="Min_Length"
                          label="Subject Name "
                          placeholder="English"
                          type="text"
                          errorMessage="Min 1 chars."
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                          validate={{
                            required: { value: true },
                            minLength: {
                              value: 1,
                              errorMessage: "Min 1 chars.",
                            },
                          }}
                        />
                        <div className="form-group  m-b-0">
                          <button
                            onClick={isEdit ? handleSubjectEdit : addClass}
                            className="btn btn-primary"
                          >
                            {loading ? (
                             <TailSpin
                             visible={true}
                             height="80"
                             width="80"
                             color="#4fa94d"
                             ariaLabel="tail-spin-loading"
                             radius="1"
                             wrapperStyle={{}}
                             wrapperClass=""
                             />
                            ) : isEdit ? (
                              "Update"
                            ) : (
                              "Submit"
                            )}
                          </button>{" "}
                          &nbsp;
                          <button
                            type="reset"
                            onClick={() => {
                              setIsEdit(false);
                              setName("");
                              setSubjectId("");
                            }}
                          >
                            cancel
                          </button>
                        </div>
                      </AvForm>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="6" className="p-3">
              <Row>
                <Col>
                  <Card>
                    <CardBody>
                      <BootstrapTable
                        keyField="package"
                        data={data}
                        columns={columns}
                      />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Subject;
