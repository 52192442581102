import React, { Component, useEffect, useState } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button, Alert } from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
// import { api } from "../../../helpers/apiLinks";
// import { del, get, post } from "../../../helpers/apiCalls";
import Select from "react-select";
// import '@vtaits/react-color-picker/dist/index.css';
import "react-datepicker/dist/react-datepicker.css";
import Switch from "react-switch";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getAllKids } from "../../redux/slices/kid.slice";
import { addVideo, getAllCategories, getAllCategory } from "../../redux/slices/user.slice";
import ImageModal from "components/imageGallery";
import swal from 'sweetalert';

let images = [
  require("../../assets/img/avatar/avatar-1.png"),
  require("../../assets/img/avatar/avatar-2.png"),
  require("../../assets/img/avatar/avatar-3.png"),
  require("../../assets/img/avatar/avatar-4.png"),
  require("../../assets/img/avatar/avatar-5.png"),
  require("../../assets/img/avatar/avatar-6.png"),
];

const Offsymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
    </div>
  );
};

const OnSymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
    </div>
  );
};
let date = new Date();

const AddVideo = () => {
  const categories = useSelector(state => state.user.categories)
  const isLoading = useSelector(state => state.user.isLoading)
  const success = useSelector(state => state.user.success)
  const { kids } = useSelector(state => state.kid);
  const [activeTab, setActiveTab] = useState('1')
  const [activeother, setActiveother] = useState('1')
  const startDate = new Date();
  const [visible, setVisible] = useState(false)
  const [modal_standard, setModal_standard] = useState(false)
  const [selectedGroup, setSelectedGroup] = useState(null)
  const [release, setRelease] = useState("")
  const [selectedMulti, setSelectedMulti] = useState(null)
  const [activeTab1, setActiveTab1] = useState("5")
  const [isFree, setIsFree] = useState(false)
  const [isError, setError] = useState()
  const [thumbnail, setThumbnail] = useState()
  const [Types, setTyoes] = useState({
    label: "Types",
    options: [],
  },)
  const [name, setName] = useState("")
  const [video, setVideo] = useState({})
  const [display, setDisplay] = useState(false)
  const [openModalStateName, setOpenModalStateName] = useState()
  const [isHide, setIsHide] = useState(false)
  const Class = "";
  const msg = "";
  const [alert14, setAlert14] = useState(false)
  const [category, setCategory] = useState()
  const dispatch = useDispatch()
  const [stateKey, setStateKey] = useState()
  const [serialNo, setSerialNo] = useState(0)
  useEffect(() => {
    dispatch(getAllCategory())
  }, [])
  useEffect(() => {
    // console.log(stateKey)
    if (stateKey && stateKey.video) {
      setVideo(stateKey.video)
    } else if (stateKey && stateKey.thumbnail) {
      setThumbnail(stateKey.thumbnail)
    }
  }, [stateKey])
  //   constructor(props) {
  //     super(props);
  //     this.state = {
  //       activeTab: "1",
  //       activeother: "1",
  //       startDate: new Date(),
  //       users: [],
  //       totalUsers: "",
  //       schoolCount: "",
  //       kidsCount: "",
  //       visible: false,
  //       modal_standard: false,
  //       selectedGroup: null,
  //       release: "",
  //       selectedMulti: null,
  //       totalUsers: "",
  //       data: [],
  //       activeTab1: "5",
  //       category: "",
  //       categoryType: {},
  //       display: false,
  //       isError: false,
  //       kids: [],
  //       video: { url: "" },
  //       name: "",
  //       Classes: [
  //         {
  //           label: "Classes",
  //           options: [],
  //         },
  //       ],
  //       Types: [
  //         {
  //           label: "Types",
  //           options: [],
  //         },
  //       ],
  //       Category: [
  //         {
  //           label: "Category",
  //           options: [],
  //         },
  //       ],
  //       Class: "",
  //       type: "",
  //       launchDate: "",
  //       isHide: false,
  //       isFree: false,
  //       selectedFiles: [],
  //     };
  //   }

  const componentDidMount = () => {
    getClass();
    this.props.activateAuthLayout();
    // this.getAllUser();
    getTypeCategory();
  }
  const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };
  /**
   * Formats the size
   */
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  console.log(isLoading)
  useEffect(() => {
    dispatch(getAllKids())
    dispatch(getAllCategories())
  }, [])

  useEffect(() => {
    if (success == "Video successfully edited") {
      swal({
        icon: 'success',
        title: 'Success!',
        text: 'Your changes have been successfully submitted.',
        timer: 2000, // Automatically close after 2 seconds
        timerProgressBar: true,
        showConfirmButton: false
      }).then(() => {
        setSelectedGroup(null)
        setIsFree(false)
        setIsHide(false)
        setVideo({})
        setThumbnail({})
        setName("")
      });
    }
  }, [success])

  const getClass = () => {
    let optionGroup = [...this.state.Classes];
    // get("categories", true)
    //   .then(({ data }) => {
    //     console.log("===>", data);
    //     if (data.success) {
    //       let Types = [
    //         {
    //           label: "Types",
    //           options: [
    //             { label: "Songs", value: data.songs },
    //             { label: "Phonics", value: data.phonics },
    //             { label: "Specials", value: data.specials },
    //             { label: "classic", value: data.classic },
    //           ],
    //         },
    //       ];
    //       // let options = data.map(({ name, _id }) => {
    //       //   return { label: name, value: _id };
    //       // });
    //       this.setState({ Types });
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  const postVideo = (e) => {
    e.preventDefault();
    const {
      selectedGroup,
      selectedMulti,
      Classes,
      Types,
      Class,
      type,
      name,
      url,
      isHide,
      isFree,
      selectedFiles,
    } = this.state;
    if (name && url && Class && type && selectedFiles && selectedFiles.length) {
      // post(api.POSTVIDEO, true)
      // .then(({ data }) => {
      // })
    }
  };
  //Select
  const handleSelectGroup = (name, SelectedGroupValue) => {
    setSelectedGroup({
      ...selectedGroup,
      [name]: SelectedGroupValue
    }
    );
    if (name == "class") {
    } else if (name == "type") {
      setCategory(
        {
          label: "Category",
          options: SelectedGroupValue.value.map((val) => {
            return { label: val.name, value: val._id };
          }),
        });
    }
    // this.setState({ [name]: selectedGroup });
    // let { categoryType } = this.state;
    // let { classes } = categoryType;
    // console.log(classes);
    // if (name == "class") {
    // } else if (name == "type") {
    //   console.log("TEST BABY", selectedGroup.value);
    //   this.setState({
    //     Category: [
    //       {
    //         label: "Category",
    //         options: selectedGroup.value.map((val) => {
    //           return { label: val.name, value: val._id };
    //         }),
    //       },
    //     ],
    //   });
    // }
  };
  const handleMulti = (selectedMulti) => {
    setSelectedMulti({ selectedMulti });
  };

  const addVideoApi = (e) => {
    e.preventDefault();
    if (
      name !== "" &&
      video !== "" &&
      selectedGroup.category !== "" &&
      thumbnail !== "" &&
      // release !== "" &&
      isFree !== ""
    ) {
      let date = new Date().toISOString();
      if (release && release < date) {
        setError({
          isError: true,
          msg: "date must be future date",
        });
      } else {
        let data = {
          category: selectedGroup.category.value,
          url: video && video.url,
          // url: 'https://vimeo.com/540018589',
          thumbnail: thumbnail.url,
          isFree,
          isHide,
          launchDate: release ? release : "",
          name,
          serialNo,
        };
   
        dispatch(addVideo(data))

        // post(api.VIDEOADD, data, true)
        //   .then(({ data }) => {
        //     // this.setState({
        //     //   loading: false,
        //     //   // video: { url: "" },
        //     //   Type: "",
        //     //   // category: "",
        //     //   Category: "",
        //     //   isFree: false,
        //     //   thumbnail: "",
        //     //   name: "",
        //     // });
        //     // console.log("ADDED SUCCESS", data);
        //     this.setState({
        //       isError: false,
        //       msg: false,
        //     });
        //     this.notify();
        //     window.location.reload();
        //   })
        //   .catch((err) => {
        //     this.setState({ loading: false });
        //     console.log("err", err);
        //   });
      }
    } else {
      setError({
        isError: true,
        msg: false,
      });
    }
  };

  //   removeBodyCss() {
  //     document.body.classList.add("no_padding");
  //   }
  const getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };
  //   tog_standard(kids) {
  //     console.log("DONE");
  //     this.setState({
  //       modal_standard: !this.state.modal_standard,
  //       kids,
  //     });
  //     this.removeBodyCss();
  //   }

  //   onDelete = (id, i) => {
  //     let rows = this.state.data.rows;
  //     let confirm = rows.filter((val) => id !== val._id);
  //     this.setState({
  //       data: { ...this.state.data, rows: confirm },
  //     });

  //     del(`${api.USERS}/${id}`, true)
  //       .then(({ data }) => {
  //         console.log("DELETE SUCCESS", data);
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   };

  console.log({serialNo})

  const rows = React.useMemo(() =>
    kids.map((val, index) => {
      return {
        ...val,
        name: (
          <span>
            {val.name}
            <p className="m-0 text-muted">
              On {moment(val.created).format("DD MMM, YYYY")}
            </p>
          </span>
        ),
        email: val.parent && val.parent.email,
        dob: moment(val.created).format("DD MMM, YYYY"),
        avatar: (
          <span>
            <img
              style={{ width: 30, height: 30 }}
              src={getImage(val.avatar)}
            />
          </span>
        ),
      };
    })
    , [kids]);

  const data = React.useMemo(() => ({
    columns: [
      {
        label: "Avatar",
        field: "avatar",
        sort: "asc",
        width: 270,
      },
      {
        label: "Name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "School Code",
        field: "schoolCode",
        sort: "asc",
        width: 270,
      },
      {
        label: "Favourite Subject",
        field: "favouriteSubject",
        sort: "asc",
        width: 270,
      },
      {
        label: "Date of Birth",
        field: "dob",
        sort: "asc",
        width: 270,
      },
      {
        label: "Parent Email",
        field: "email",
        sort: "asc",
        width: 270,
      },
    ],
    rows
  }), [rows]);

  //   getAllUser = () => {
  //     get(api.KIDS, true)
  //       .then(({ data }) => {
  //         if (data.success) {
  //           let data2 = {
  //             columns: [
  //               {
  //                 label: "Avatar",
  //                 field: "avatar",
  //                 sort: "asc",
  //                 width: 270,
  //               },
  //               {
  //                 label: "Name",
  //                 field: "name",
  //                 sort: "asc",
  //                 width: 150,
  //               },
  //               {
  //                 label: "School Code",
  //                 field: "schoolCode",
  //                 sort: "asc",
  //                 width: 270,
  //               },
  //               {
  //                 label: "Favourite Subject",
  //                 field: "favouriteSubject",
  //                 sort: "asc",
  //                 width: 270,
  //               },
  //               {
  //                 label: "Date of Birth",
  //                 field: "dob",
  //                 sort: "asc",
  //                 width: 270,
  //               },
  //               {
  //                 label: "Parent Email",
  //                 field: "email",
  //                 sort: "asc",
  //                 width: 270,
  //               },
  //             ],
  //             rows: data.kids.map((val, index) => {
  //               return {
  //                 ...val,
  //                 name: (
  //                   <span>
  //                     {val.name}
  //                     <p className="m-0 text-muted">
  //                       On {moment(val.created).format("DD MMM, YYYY")}
  //                     </p>
  //                   </span>
  //                 ),
  //                 email: val.parent && val.parent.email,
  //                 dob: moment(val.created).format("DD MMM, YYYY"),
  //                 avatar: (
  //                   <span>
  //                     <img
  //                       style={{ width: 30, height: 30 }}
  //                       src={this.getImage(val.avatar)}
  //                     />
  //                   </span>
  //                 ),
  //               };
  //             }),
  //           };
  //           this.setState({
  //             users: data.user,
  //             totalUsers: data.totalUsers,
  //             schoolCount: data.schoolCount,
  //             kidsCount: data.kidsCount,
  //             usersCount: data.usersCount,
  //             data: data2,
  //           });
  //         } else {
  //           console.log(data);
  //         }
  //         console.log("DATA IS HERE", data);
  //       })
  //       .catch((err) => {
  //         console.log("SOME ERROR", err);
  //       });
  //   };

  const getTypeCategory = () => {
    // get(api.CATEGORY, true)
    //   .then(({ data }) => {
    //     let { classes } = data;
    //     let Classes = [
    //       {
    //         label: "Classes",
    //         options: classes.map((val) => {
    //           return {
    //             label: val.name,
    //             value: val._id,
    //           };
    //         }),
    //       },
    //     ];
    //     console.log("DATAATTATA", classes);
    //     this.setState({ Classes, categoryType: data });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const toggleStock = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(
        tab
      );
    }
  }

  const toggleMessages = (tab) => {
    if (activeother !== tab) {
      setActiveother({
        tab
      });
    }
  }

  const notify = () => toast("Video Added Succussfully");
  function toastify() {
    const notify = () => toast("Video Added Succussfully");

    return (
      <div>
        <button onClick={notify}>Notify !</button>
        <ToastContainer />
      </div>
    );
  }
  return (
    <React.Fragment>
      <div className="content dasboard-content" style={{ paddingTop: '80px' }}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">KIDS</h4>
                <ToastContainer />
                <ol className="breadcrumb">
                  <li className="breadcrumb-item active">
                    Welcome to Blinkid Kids
                  </li>
                </ol>
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {isError && (
                    <Alert
                      color="danger"
                      isOpen={alert14}
                      toggle={() => setAlert14(false)}
                    >
                      <i className="mdi mdi-block-helper mr-2"></i>
                      {msg
                        ? msg
                        : "Credential cannot be Empty!"}
                    </Alert>
                  )}
                  <h4 className="mt-0 header-title">Add Video</h4>
                  <form>
                    <div className="form-group">
                      <label for="validationCustom01">Video Title</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        placeholder=""
                        value={name}
                        onChange={(event) => {
                          var count = event.target.value;
                          setName(count);
                        }}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    {/* <div className="form-group">
                        <label className="control-label">Class</label>

                        <Select
                          value={Class.label}
                          onChange={(evt) =>
                            this.handleSelectGroup("class", evt)
                          }
                          options={Classes}
                        />
                        
                      </div> */}
                    <div className="form-group">
                      <label for="validationCustom01">Video URL</label>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          style={{ width: "80%" }}
                          placeholder=""
                          value={video.url}
                          onChange={(event) => {
                            var count = event.target.value;
                            setVideo({ url: count });
                          }}
                        />
                        <ToastContainer />
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            setDisplay(!display);
                            setOpenModalStateName('video');
                          }}
                        >
                          Select Video
                        </Button>
                      </div>
                    </div>

                    <div className="form-group mb-0">
                      <label className="control-label">Thumbnail</label>
                      <br />
                      <Button
                        onClick={(e) => {
                          e.preventDefault();
                          setDisplay(!display);
                          setOpenModalStateName('thumbnail');
                        }}
                      >
                        {" "}
                        {thumbnail
                          ? "Change Thumbnail"
                          : "Select Thumbnail"}{" "}
                      </Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;{" "}
                      {thumbnail &&
                        thumbnail.originalname}
                      <ImageModal
                        activeTab1={activeTab1}
                        select={(t) =>
                          setStateKey({
                            [openModalStateName]: t,
                          })
                        }
                        setActiveTab={(activeTab) => setActiveTab1(activeTab)}
                        display={display}
                        setDisplay={(display) => setDisplay(display)}
                      />
                    </div>
                    <div className="form-group mb-0">
                      <label className="control-label">
                        Video Categories
                      </label>
                      <Select
                        // value={selectedGroup?.category?.label}
                        onChange={(evt) =>
                          handleSelectGroup("type", evt)
                        }
                        options={categories && categories?.options}
                      />
                    </div>
                    <div className="form-group">
                      <label for="validationCustom01">Date And Time</label>
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <input
                          className="form-control"
                          type="datetime-local"
                          value={release}
                          onChange={(e) =>
                            setRelease(e.target.value)
                          }
                          id="example-datetime-local-input"
                        />
                      </div>
                    </div>
                    <div
                      style={{ marginTop: 10 }}
                      className="form-group mb-0"
                    >
                      <label className="control-label">
                        Video Sub-Categories
                      </label>
                      <Select
                        //  value={selectedGroup?.type?.label}
                        onChange={(evt) =>
                          handleSelectGroup("category", evt)
                        }
                        options={category?.options}
                      />
                    </div>
                    <div className="form-group">
                      <label for="validationCustom01">
                        Serial No - Order
                      </label>
                      <input
                        type="number"
                        className="form-control"
                        style={{ width: 80 }}
                        id="validationCustom01"
                        placeholder=""
                        value={serialNo}
                        onChange={(event) => {
                          var count = event.target.value;
                          setSerialNo(count);
                        }}
                      />
                      <div className="valid-feedback">
                        Looks good!
                      </div>
                    </div>
                    <div className="form-group" style={{ paddingTop: 20 }}>
                      <label
                        style={{ marginRight: 20 }}
                        className="control-label"
                      >
                        Free
                      </label>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#1bb99a"
                        onChange={() => setIsFree(!isFree)}
                        checked={isFree}
                      />
                    </div>
                    <div className="form-group" style={{ paddingTop: 20 }}>
                      <label
                        style={{ marginRight: 20 }}
                        className="control-label"
                      >
                        Hide
                      </label>
                      <Switch
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        onColor="#1bb99a"
                        onChange={() =>
                          setIsHide(!isHide)
                        }
                        checked={isHide}
                      />
                    </div>
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          type="submit"
                          disabled={isLoading}
                          color="primary"
                          className="mr-1"
                          onClick={addVideoApi}
                        >
                          {isLoading ? "...Loading" : "Submit"}
                        </Button>
                      </div>
                    </FormGroup>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
}

export default AddVideo;