import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Modal,
  Button,
  CardHeader,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
// import { GetUsers } from "../../../store/dashboard/saga";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
// import { api } from "../../../helpers/apiLinks";
// import { del, get } from "../../../helpers/apiCalls";
import { MDBDataTable } from "mdbreact";
// import user2 from "../../../images/users/user-2.jpg";
import StarRatings from "react-star-ratings";
import { CSVLink, CSVDownload } from "react-csv";
let images = [
    require("../../assets/img/avatar/avatar-1.png"),
    require("../../assets/img/avatar/avatar-2.png"),
    require("../../assets/img/avatar/avatar-3.png"),
    require("../../assets/img/avatar/avatar-4.png"),
    require("../../assets/img/avatar/avatar-5.png"),
    require("../../assets/img/avatar/avatar-6.png"),
  ];

const Payment = () => {
  const [modalLarge, setModalLarge] = useState(false);
  const [selectedKid, setSelectedKid] = useState({});
  const [payments, setPayments] = useState([]);
  const [filterResults, setFilterResults] = useState([]);
  const [activeTab, setActiveTab] = useState();
  const [activeother, setActiveother] = useState();

  const dispatch = useDispatch()

  useEffect(() => {
    getAllUser();
  }, []);

  const getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };

  const tog_large = (e, val) => {
    e.preventDefault();
    setModalLarge(!modalLarge);
    setSelectedKid(val);
  };

  const calculateEndData = (date, check) => {
    // let datE = moment(date);
    // let b = datE.add("1", check);
    // return b;
  };

  const getAllUser = () => {
    // get(api.PAYMENTS, true)
    //   .then(({ data }) => {
    //     if (data.success) {
    //       let data2 = {
    //         columns: [
    //           { label: "User Email", field: "email", sort: "asc", width: 270 },
    //           { label: "Credit Card Number", field: "card", sort: "asc", width: 270 },
    //           { label: "Amount", field: "amount", sort: "asc", width: 270 },
    //           { label: "Interval", field: "interval", sort: "asc", width: 270 },
    //           { label: "Start Date", field: "start_date", sort: "asc", width: 270 },
    //           { label: "End Date", field: "end_date", sort: "asc", width: 270 },
    //           { label: "Status", field: "status", sort: "asc", width: 270 }
    //         ],
    //         rows: data && data.data && data.data.length > 0 && data.data.map((value, index) => ({
    //           ...value,
    //           amount: value && value.amount + " $",
    //           status: (
    //             <div>
    //               <i className={`mdi mdi-checkbox-blank-circle mr-1 ${value && value.status ? "text-success" : " text-warning"}`}></i>{" "}
    //               {value && value.status && value.status.toUpperCase()}
    //             </div>
    //           ),
    //           start_date: (
    //             <div>
    //               {value && moment(new Date(value.start_date * 1000).toISOString()).format("MMM Do YYYY")}
    //             </div>
    //           ),
    //           end_date: (
    //             <div>
    //               {value && calculateEndData(new Date(value.start_date * 1000).toISOString(), value && value.interval).format("MMM Do YYYY")}
    //             </div>
    //           )
    //         }))
    //       };
    //       setPayments(data.data);
    //       setData(data2);
    //     } else {
    //       console.log(data);
    //     }
    //   })
    //   .catch((err) => {
    //     console.log("SOME ERROR", err);
    //   });
  };

  const filterResultsHandler = async (e) => {
    let { selectedKid } = selectedKid;
    if (selectedKid.results) {
      const lowercasedFilter = e.target.value.toLowerCase();
      const filteredData = await selectedKid.results.filter((val) => {
        if (val.video.name) {
          return val.video.name.toLowerCase().includes(lowercasedFilter);
        } else {
          return val;
        }
      });
      setFilterResults(filteredData);
    }
  };

  const onDelete = (id, i) => {
    // let rows = data.rows;
    // let confirm = rows.filter((val) => id !== val._id);
    // setData({ ...data, rows: confirm });

    // del(`${api.USERS}/${id}`, true)
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

  const toggleStock = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const toggleMessages = (tab) => {
    if (activeother !== tab) {
      setActiveother(tab);
    }
  };

//   const rows = React.useMemo(() => 
//   payment && payment.data && payment.data.length > 0 && payment.data.map((value, index) => ({
//     ...value,
//     amount: value && value.amount + " $",
//     status: (
//       <div>
//         <i className={`mdi mdi-checkbox-blank-circle mr-1 ${value && value.status ? "text-success" : " text-warning"}`}></i>{" "}
//         {value && value.status && value.status.toUpperCase()}
//       </div>
//     ),
//     start_date: (
//       <div>
//         {value && moment(new Date(value.start_date * 1000).toISOString()).format("MMM Do YYYY")}
//       </div>
//     ),
//     end_date: (
//       <div>
//         {value && calculateEndData(new Date(value.start_date * 1000).toISOString(), value && value.interval).format("MMM Do YYYY")}
//       </div>
//     )
//   }))
// , [category]
// );

//   const data = React.useMemo(() => ({
//     columns: [
//         { label: "User Email", field: "email", sort: "asc", width: 270 },
//         { label: "Credit Card Number", field: "card", sort: "asc", width: 270 },
//         { label: "Amount", field: "amount", sort: "asc", width: 270 },
//         { label: "Interval", field: "interval", sort: "asc", width: 270 },
//         { label: "Start Date", field: "start_date", sort: "asc", width: 270 },
//         { label: "End Date", field: "end_date", sort: "asc", width: 270 },
//         { label: "Status", field: "status", sort: "asc", width: 270 }
//       ],
//       rows
//     }), [rows]);

  return (
    <React.Fragment>
      <div className="content dasboard-content" style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title">Payments</h4>
              </div>
            </div>
          </div>

          <Row>
            <Col>
              <Card>
                <CardHeader>
                  <Button className="btn">
                    {/* <CSVLink
                      data={payments ? payments.map((val) => ({
                        "User Email": val.email,
                        "Credit Card Number": val.card,
                        Amount: val.amount,
                        Interval: val.interval,
                        Status: val.status.toUpperCase(),
                        "Start Date": moment(new Date(val.start_date * 1000).toISOString()).format("MMM Do YYYY"),
                        "End Date": calculateEndData(new Date(val.start_date * 1000).toISOString(), val && val.interval).format("MMM Do YYYY")
                      })) : []
                      }
                    >
                      Download
                    </CSVLink> */}
                  </Button>
                </CardHeader>
                <CardBody>
                  {/* <MDBDataTable data={data} /> */}
                </CardBody>
              </Card>

              <Modal
                className="modal-lg"
                isOpen={modalLarge}
                toggle={tog_large}
              >
                <div className="modal-header">
                  <h5 className="modal-title mt-0" id="myLargeModalLabel">
                    {selectedKid && selectedKid.name} Detail
                  </h5>
                  <button
                    onClick={() => setModalLarge(false)}
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div
                  className="modal-body"
                  style={{ maxHeight: 700, overflowY: "auto" }}
                >
                  <div className="form-group">
                    <label for="validationCustom01">Search</label>
                    <input
                      type="text"
                      className="form-control"
                      id="validationCustom01"
                      placeholder="Video Name"
                      style={{ height: 50 }}
                      onChange={(event) => filterResultsHandler(event)}
                    />
                    <div className="valid-feedback">Looks good!</div>
                  </div>
                  {filterResults.length > 0
                    ? filterResults.map((val) => (
                      <Card style={{ height: 100 }}>
                        <CardBody>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "20%" }}>
                              <img
                                style={{ width: 100, height: "100%" }}
                                src={val.video && val.video.thumbnail}
                              />
                            </div>
                            <div
                              style={{
                                width: "75%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Video Name:
                                </span>{" "}
                                {val.video && val.video.name}
                              </div>
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Result:
                                </span>{" "}
                                <StarRatings
                                  rating={val.stars}
                                  starRatedColor="orange"
                                  numberOfStars={3}
                                  starSpacing="2px"
                                  starDimension="20px"
                                  name="rating"
                                />
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))
                    : selectedKid &&
                    selectedKid.results &&
                    selectedKid.results.map((val) => (
                      <Card style={{ height: 100 }}>
                        <CardBody>
                          <div
                            style={{
                              width: "100%",
                              height: "100%",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            <div style={{ width: "20%" }}>
                              <img
                                style={{ width: 100, height: "100%" }}
                                src={val.video && val.video.thumbnail}
                              />
                            </div>
                            <div
                              style={{
                                width: "75%",
                                height: "100%",
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                              }}
                            >
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Video Name:
                                </span>{" "}
                                {val.video && val.video.name}
                              </div>
                              <div>
                                <span style={{ fontWeight: "bold" }}>
                                  Result:
                                </span>{" "}
                                <StarRatings
                                  rating={val.stars}
                                  starRatedColor="orange"
                                  numberOfStars={3}
                                  starSpacing="2px"
                                  starDimension="20px"
                                  name="rating"
                                />
                              </div>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    ))}
                </div>
              </Modal>
            </Col>
          </Row>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Payment;