import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Button,
  Modal,
  TabContent,
  TabPane,
  Nav,
  Alert,
  NavItem,
  NavLink,
  CardHeader,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { MDBDataTable } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import Loader from "react-loader-spinner";
import SweetAlert from "react-bootstrap-sweetalert";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
// import { del, get, put, post } from "../../../helpers/apiCalls";
import ReactAudioPlayer from "react-audio-player";
import ImageModal from "components/imageGallery";
import classnames from "classnames";
import { addPhonic, deletePhonics, editPhonics, getPhonics } from "../../redux/slices/user.slice";
import moment from "moment";
import { BASIC_URL } from "utils/api";
import swal from 'sweetalert';


// Assuming `images` and `api` imports are defined elsewhere as they were in the original code

const Phonic = () => {
  // State definitions
  const [activeTab, setActiveTab] = useState("1");
  const [startDate, setStartDate] = useState(new Date());
  const [users, setUsers] = useState([]);
  const [schoolCount, setSchoolCount] = useState("");
  const [kidsCount, setKidsCount] = useState("");
  const [modalStandard, setModalStandard] = useState(false);
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [name, setName] = useState("");
  const [word, setWord] = useState("");
  const [stateKey, setStateKey] = useState("");
  const [serialNo, setSerialNo] = useState("");
  const [classesOptions, setClassesOptions] = useState([]);
  const [typesOptions, setTypesOptions] = useState([]);
  const [classSelected, setClassSelected] = useState("");
  const [typeSelected, setTypeSelected] = useState("");
  const [isFree, setIsFree] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isError, setIsError] = useState(false);
  const [selsectMulti, setSelectedMulti] = useState();
  const [classes, setClasses] = useState([]);
  const [activeTab1, setActiveTab1] = useState()
  const [isShow, setIsShow] = useState(false);
  const [playAudio, setPlayAudio] = useState()
  const [phonicsId, setPhonicsId] = useState()
  const [confirm_both, setConfirm_both] = useState(false);
  const [dynamic_description, setDynamic_description] = useState();
  const [dynamic_title, setDynamic_title] = useState();
  const [display, setDisplay] = useState();
  const [display_title, setDisplay_title] = useState();
  const [display_description, setDisplay_description] = useState();
  const [loading, setLoading] = useState();
  const [isEdit, setIsEdit] = useState();
  const [alphabets, setAlphabets] = useState([]);
  const [activeTab2, setActiveTab2] = useState();
  const [sound, setSound] = useState();
  const [image, setImage] = useState();
  const [success_dlg, setSuccess_dlg] = useState();
//   const [toggle1, setToggle1] = useState();
  const [toggle2, setToggle2] = useState();
  const [alert14, setAlert14] = useState();
  const [audio, setAudio] = useState();
  const [kids, setKids] = useState();
  const [activeother, setActiveOther] = useState();
  const [error_dlg, seterror_dlg] = useState(false);
  const dispatch = useDispatch()
  const {phonics : Phonics, success} = useSelector(state => state.user)

  let images = [
    require("../../assets/img/avatar/avatar-1.png"),
    require("../../assets/img/avatar/avatar-2.png"),
    require("../../assets/img/avatar/avatar-3.png"),
    require("../../assets/img/avatar/avatar-4.png"),
    require("../../assets/img/avatar/avatar-5.png"),
    require("../../assets/img/avatar/avatar-6.png"),
  ];

  // useEffect to mimic componentDidMount for fetching initial data
  useEffect(() => {
    getClass();
    // props.activateAuthLayout();
    dispatch(getPhonics())
    // Additional fetch calls can be added here
  }, []);

  useEffect(() => {
  if(stateKey.sound){
    setSound(stateKey.sound)
  } else if (stateKey.image){
    setImage(stateKey.image)
  } else if (stateKey.audio){
    setAudio(stateKey.audio)
  }
  }, [stateKey])

  console.log(stateKey)


  const getClass = async () => {
    // try {
    //   const response = await get(api.CLASS, true); // Assuming 'get' returns a promise
    //   console.log("===>", response.data);
    //   if (response.data.classes && response.data.classes.length) {
    //     let options = response.data.classes.map(({ name, _id }) => ({ label: name, value: _id }));
    //     console.log({ options });
    //     // Assuming Classes is part of the state that needs to be updated
    //     setClasses(currentClasses => {
    //       const optionGroup = [...currentClasses];
    //       optionGroup[0]["options"] = options;
    //       return optionGroup;
    //     });
    //   }
    // } catch (err) {
    //   console.log(err);
    // }
  };
  
  const handleAddPhonics = async (e) => {
    e.preventDefault();
    console.log(        name !== "" ,
    audio !== undefined ,
    alphabets.length > 0 ,
    serialNo !== ""
    ,audio)
    if (
        name !== "" &&
        audio !== undefined &&
        alphabets.length > 0 &&
        serialNo !== ""
      ) {
    dispatch(addPhonic({letter: name,
        serialNo: serialNo,
        audio: audio.url,
        vocab: alphabets.map((val) => {
          return { image: val.image.url, word: val.word, audio: val.audio.url };
        }),}))
        if(success === 'Successfully Phonic add') {
            setImage("")
            setAudio("")
            setSound("")
            setAlphabets("")
            setSerialNo("")
            setName("")
        }
}
    // // Validate your inputs...
    // try {
    //   const response = await post(api.PHONCS_ADD, phonicsData);
    //   // Update your state with the new phonics...
    //   getPhonics(); // Or directly append to the list if appropriate
    // } catch (err) {
    //   console.log(err);
    // }
  };
  
  const handleEditPhonics = async (e) => {
    e.preventDefault();
    if (
      name !== "" &&
      audio !== undefined &&
      serialNo !== ""
    ) {
    dispatch(editPhonics({id : phonicsId, data : {letter: name,
      serialNo: serialNo,
      audio: audio.url,
}}))
if(success === 'Sight successfully edited'){
  toggle1("6")
}
    }
    // // Validate your inputs...
    // try {
    //   const response = await put(api.PHONCS + phonicsId, updatedData);
    //   // Update your state to reflect the changes...
    //   getPhonics(); // Refresh or directly update the list
    // } catch (err) {
    //   console.log(err);
    // }
  };

  const uploadFile = (files, allFiles) => {
    let dummy = {
      image: "images",
      audio: "audios",
      video: "videos",
    };
    files.length &&
      files.map((val) => {
        let file = new FormData();
        file.append("file", val.file);
        let type = val.file.type.split("/")[0];
        console.log("DATATTA", type);

        // return post(api.FILE, file, true)
        //   .then(({ data }) => {
        //     console.log("COMPLETED", data);
        //     getAll();
        //     allFiles.forEach((f) => f.remove());
        //     // setState({
        //     //   ...allFiles,
        //     //   [dummy[type]]: [
        //     //     ...allFiles[dummy[type]],
        //     //     { ...data, originalname: data.name },
        //     //   ],
        //     // });
        //     return data;
        //   })
        //   .catch((err) => {
        //     console.log("ERR", err);
        //   });
      });
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };

  const toggleMessages = (tab) => {
    setActiveOther(tab);
  };
  
  const toggleStock = (tab) => {
    setActiveTab(tab);
  };
  
  const onDelete = (id) => {
    // Update your state to reflect the deletion...
    // setData(currentData => currentData.filter(data => data.id !== id));
    dispatch(deletePhonics(id))

  };
  
  const toggle1 = (tab) => {
    setActiveTab1(tab);
  };
  
  const tog_standard = (kids) => {
    setModalStandard(!modalStandard);
    setKids(kids);
  };

  const handleSelectGroup = (name, selectedGroup) => {
    // Assuming you have a state setup for each possible name
    if (name === 'selectedGroup') {
      setSelectedGroup(selectedGroup);
    }
    // Add other conditions as needed
  };
  
  const handleMulti = (selectedMulti) => {
    setSelectedMulti(selectedMulti);
  };
  
  const handleAcceptedFiles = (files) => {
    const formattedFiles = files.map(file => ({
      ...file,
      preview: URL.createObjectURL(file),
      formattedSize: formatBytes(file.size)
    }));
    setSelectedFiles(formattedFiles);
  };
  const rows = React.useMemo(() => 
  Phonics && Phonics?.map((val, index) => {
    return {
      ...val,
      letter: (
        <div>
          <span
            style={{
              fontSize: 10,
              fontWeight: "bold",
              color: "black",
            }}
          >
            {val.letter}
          </span>
          <br />
          {val.audio && (
            <i
              onClick={() => {
                setPlayAudio(val.audio);
                    setIsShow(true)
              }}
              style={{ fontSize: 24, cursor: "pointer" }}
              className={`mdi mdi-play text-primary`}
            ></i>
          )}
        </div>
      ),
      vocab1: (
        <div style={{ display: "flex" }}>
          {val.vocab.map((value) => (
            <div style={{ marginLeft: 10, textAlign: "center" }}>
              <img style={{ height: 60 }}   src={`${BASIC_URL}${value.image}`} />
              <br />
              {value.word}
              <br />
              {value.audio && (
                <i
                  onClick={() => {
                    setPlayAudio(value.audio);
                    setIsShow(true)
                  }}
                  style={{ fontSize: 24, cursor: "pointer" }}
                  className={`mdi mdi-play text-primary`}
                ></i>
              )}
            </div>
          ))}
        </div>
      ),
      action: (
        <>
          <Button
            onClick={(e) => {
              setSerialNo(val.serialNo)
              setName(val.letter)
              setActiveTab1("5")
              setPhonicsId(val._id)
              setIsEdit(true)
              setAudio(val.audio)
            }}
            type="submit"
            color="primary"
            className="mr-1"
          >
            Edit
          </Button>
          <Button
            onClick={(e) =>
              {setConfirm_both(true)
              setPhonicsId(val._id)

            }
            }
            type="submit"
            color="danger"
            className="mr-1"
          >
            Delete
          </Button>
        </>
      ),
    };
                })
, [Phonics]);

  const data = React.useMemo(() => ({
    columns: [
      {
        label: "Letter",
        field: "letter",
        sort: "asc",
        width: 270,
      },
      {
        label: "Serial No",
        field: "serialNo",
        sort: "asc",
        width: 150,
      },
      {
        label: "vocab & images",
        field: "vocab1",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 270,
      },
    ],
      rows
    }), [rows]);

    useEffect(() => {
      if (confirm_both) {
        swal({
          title: "Are you sure?",
          text: "You won't be able to revert this!",
          icon: "warning",
          buttons: {
            cancel: {
              text: "Cancel",
              value: null,
              visible: true,
              className: "btn-success",
              closeModal: true,
            },
            confirm: {
              text: "Confirm",
              value: true,
              visible: true,
              className: "btn-danger",
              closeModal: true
            }
          },
          dangerMode: true,
        })
        .then((willDelete) => {
          if (willDelete) {
             onDelete(phonicsId)
          } else {
            // Handling cancelation, similar to your old onCancel
            setConfirm_both(false);
            seterror_dlg(true);
            setDynamic_title("Cancelled");
            setDynamic_description("Your imaginary file is safe :");
          }
        });      
      }
    }, [confirm_both]);

  return (
    <React.Fragment>
    <div className="content dasboard-content" style={{paddingTop : '80px'}}>
      <div className="container-fluid">
        <div className="page-title-box">
          <div className="row align-items-center">
            <ToastContainer />
            <Modal
              className="modal-md"
              isOpen={isShow}
              toggle={() => setIsShow(!isShow)}
            >
              <ModalHeader>Audio Player</ModalHeader>
              <ModalBody>
                <ReactAudioPlayer
                  style={{ marginLeft: 10 }}
                  src={playAudio}
                  controls
                  autoPlay
                />
              </ModalBody>
            </Modal>
            <div className="col-sm-6">
              <h4 className="page-title">Phonics</h4>
            </div>
            {/* {confirm_both ? (
              <SweetAlert
                title="Are you sure?"
                warning
                showCancel
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                onConfirm={() => {
                  deletePhonics(phonicsId);
                }}
                onCancel={() => {
                    setConfirm_both(false);
                    seterror_dlg(true);
                    setDynamic_title("Cancelled");
                    setDynamic_description("Your imaginary file is safe :");
                  }}                  
              >
                You won't be able to revert this!
              </SweetAlert>
            ) : null} */}

            {success_dlg ? (
              <SweetAlert
                success
                title={dynamic_title}
                onConfirm={() =>  setSuccess_dlg(false)}
              >
                {dynamic_description}
              </SweetAlert>
            ) : null}
          </div>
        </div>

        <Nav pills className="navtab-bg nav-justified">
          <NavItem>
            <NavLink
              className={classnames(
                activeTab1 === "5",
              )}
              onClick={() => {
                toggle1("5");
              }}
            >
              Add Phonic
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames(
               activeTab1 === "6",
              )}
              onClick={() => {
                toggle1("6");
              }}
            >
              Show Phonic
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab1}>
          <TabPane tabId="5" className="p-3">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <Card>
                      <CardBody>
                        {isError && (
                          <Alert
                            color="danger"
                            isOpen={alert14}
                            toggle={setAlert14(false)}
                          >
                            <i className="mdi mdi-block-helper mr-2"></i>
                            Credential cannot be Empty!
                          </Alert>
                        )}

                        <h4 className="mt-0 header-title">Add Phonics</h4>
                        <form>
                          {/* <div className="form-group">
                            <label className="control-label">Class</label>
                            <Select
                              value={Class}
                              onChange={(evt) =>
                                this.handleSelectGroup("Class", evt)
                              }
                              options={Classes}
                            />
                          </div> */}
                          <div className="form-group">
                            <label for="validationCustom01">Letter</label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              value={name}
                              onChange={(event) => {
                                var count = event.target.value;
                                setName(count);
                              }}
                            />
                            <div className="valid-feedback">
                              Looks good!
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="validationCustom01">
                              Letter Audio
                            </label>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              <Button
                                style={{ marginLeft: 15 }}
                                onClick={(e) => {
                                  e.preventDefault();
                                  setDisplay(true)
                                  setStateKey("audio")
                                }}
                              >
                                {audio
                                  ? "Change Audio"
                                  : "Select Audio"}
                              </Button>
                              {audio ? (
                                <ReactAudioPlayer
                                  style={{ marginLeft: 10 }}
                                  src={audio.url}
                                  controls
                                />
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                          <div className="form-group">
                            <label for="validationCustom01">
                              Serial No
                            </label>
                            <input
                              type="number"
                              style={{ width: 80 }}
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              value={serialNo}
                              onChange={(event) => {
                                var count = event.target.value;
                               setSerialNo(count);
                              }}
                            />
                            <div className="valid-feedback">
                              Looks good!
                            </div>
                          </div>

                          {!isEdit && (
                            <>
                              <div className="form-group">
                                <label for="validationCustom01">Word</label>
                                <div style={{ display: "flex" }}>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="validationCustom01"
                                    placeholder=""
                                    style={{ width: "30%" }}
                                    value={word}
                                    onChange={(event) => {
                                      var count = event.target.value;
                                     setWord(count)
                                    }}
                                  />
                                  <Button
                                    style={{ marginLeft: 15 }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setDisplay(!display)
                                      setStateKey("image")
                                    }}
                                  >
                                    {image
                                      ? "Change Image"
                                      : "Select Image"}
                                  </Button>
                                  <span style={{ marginLeft: 10 }}>
                                    {image
                                      ? image.originalname &&
                                        image.originalname
                                          .length > 15
                                        ? image.originalname.substring(
                                            0,
                                            15 - 3
                                          ) + "..."
                                        : image.originalname
                                      : ""}
                                  </span>
                                  <Button
                                    style={{ marginLeft: 15 }}
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setDisplay(!display)
                                      setStateKey("sound")
                                    }}
                                  >
                                    {sound
                                      ? "Change Audio"
                                      : "Select Audio"}
                                  </Button>
                                  <span style={{ marginLeft: 10 }}>
                                    {sound
                                      ? sound.originalname &&
                                        sound.originalname
                                          .length > 15
                                        ? sound.originalname.substring(
                                            0,
                                            15 - 3
                                          ) + "..."
                                        : sound.originalname
                                      : ""}
                                  </span>
                                  <Button
                                    style={{ marginLeft: 15 }}
                                    color="danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      if (word !== "" && image)
                                        setAlphabets(
                                           [
                                            ...alphabets,
                                            {
                                              word,
                                              image: image,
                                              audio: sound,
                                            },
                                          ])
                                        }}
                                  >
                                    +
                                  </Button>
                                </div>
                              </div>

                              <Card>
                                <CardBody>
                                  <table>
                                    <tr>
                                      <th style={{ padding: 15 }}>Word</th>
                                      <th style={{ padding: 15 }}>Image</th>
                                      <th style={{ padding: 15 }}>Audio</th>
                                    </tr>
                                    {alphabets?.length &&
                                      alphabets.map(
                                        (val, ind) => (
                                          <tr>
                                            <td style={{ padding: 15 }}>
                                              {val.word}
                                            </td>
                                            <td style={{ padding: 15 }}>
                                              <img
                                                style={{ height: 30 }}
                                                src={val.image.url}
                                              />
                                            </td>
                                            <td style={{ padding: 15 }}>
                                              <ReactAudioPlayer
                                                style={{ marginLeft: 10 }}
                                                src={val.audio.url}
                                                controls
                                              />
                                            </td>
                                            <td>
                                              <i
                                                onClick={(e) => {
                                                  setAlphabets(
                                                  alphabets.filter(
                                                      (value, i) =>
                                                        ind !== i
                                                    )
                                                  );
                                                }}
                                                style={{
                                                  cursor: "pointer",
                                                  fontSize: 24,
                                                }}
                                                className={`mdi mdi-trash-can-outline text-danger`}
                                              ></i>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                  </table>
                                </CardBody>
                              </Card>
                            </>
                          )}
                          <ImageModal
                            activeTab1={activeTab2}
                            select={(t) =>
                              setStateKey({
                                [stateKey]: t,
                              })
                            }
                            setActiveTab={(t) =>
                              setActiveTab2(t)
                            }
                            display={display}
                            setDisplay={(display) => setDisplay(display)}
                          />
                          <FormGroup className="mb-0">
                            <div>
                              <Button
                                type="submit"
                                color="primary"
                                className="mr-1"
                                onClick={(e) =>
                                  !isEdit
                                    ? handleAddPhonics(e)
                                    : handleEditPhonics(e)
                                }
                                disable={loading}
                              >
                                {loading ? (
                                 "loading"
                                ) : isEdit ? (
                                  "Update"
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </FormGroup>
                        </form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane tabId="6" className="p-3">
            <Row>
              <Col sm="12">
                <Row>
                  <Col>
                    <Card>
                      <CardHeader>
                        {/* <div className="form-group">
                          <label className="control-label">Class</label>
                          <Select
                            value={Class1}
                            onChange={(evt) => {
                              this.getPhonics(evt.value);
                              this.setState({ Class1: evt });
                            }}
                            options={Classes}
                          />
                        </div> */}
                      </CardHeader>
                      <CardBody>
                        <MDBDataTable bordered data={data} />
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </div>
  </React.Fragment>
  );
};

export default Phonic;
