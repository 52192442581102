import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, FormGroup, Button } from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { addQuestion } from "../../redux/slices/user.slice";
// import Loader from 'react-loader-spinner';

const Question = () => {
  const [question, setQuestion] = useState("");
  const [option, setOption] = useState({});
  const [answer, setAnswer] = useState("");
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch()

  const handleChange = (event) => {
    const { value } = event.target;
    setQuestion(value);
  };

  const postVideo = () => {
    dispatch(addQuestion({question : question, options : option, answer : answer}))
    // Your logic for posting the video goes here
    // You can access question to get the question text
    setLoading(true); // Assuming you want to show loader while posting
    // Example of async operation
    setTimeout(() => {
      setLoading(false);
    }, 2000); // Simulating async operation for 2 seconds
  };

  return (
    <>
      <div className="content dasboard-content" style={{paddingTop : '80px'}}>
        <div className="container-fluid">
          <div className="page-title-box">
            <div className="row align-items-center">
              <div className="col-sm-6">
                <h4 className="page-title"> Add Question to Video</h4>
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <Card>
                <CardBody>
                  <form>
                    <div className="form-group">
                      <label htmlFor="validationCustom01">Question</label>
                      <input
                        type="text"
                        className="form-control"
                        id="validationCustom01"
                        placeholder=""
                        value={question}
                        onChange={handleChange}
                      />
                      <div className="valid-feedback">Looks good!</div>
                    </div>
                    <Row>
                        <Col>
                          <div className="form-group">
                            <label for="validationCustom01">Option A</label>
                            <div className="checkbox-wrapper-mail">
                              <input
                                type="text"
                                className="form-control"
                                id="validationCustom01"
                                placeholder=""
                                value={option.a}
                                onChange={(event) => {
                                  var count = event.target.value;
                                  setOption({...option, a : count})
                                }}
                              />
                              <div className="valid-feedback">Looks good!</div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label for="validationCustom01">Option B</label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              value={option.b}
                              onChange={(event) => {
                                var count = event.target.value;
                                setOption({...option, b : count})
                              }}
                            />
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div className="form-group">
                            <label for="validationCustom01">Option C</label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              value={option.c}
                              onChange={(event) => {
                                var count = event.target.value;
                                setOption({...option, c : count})
                              }}
                            />
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                        </Col>
                        <Col>
                          <div className="form-group">
                            <label for="validationCustom01">Option D</label>
                            <input
                              type="text"
                              className="form-control"
                              id="validationCustom01"
                              placeholder=""
                              value={option.d}
                              onChange={(event) => {
                                var count = event.target.value;
                                setOption({...option, d : count})
                              }}
                            />
                            <div className="valid-feedback">Looks good!</div>
                          </div>
                        </Col>
                      </Row>
                      <div className="form-group">
                        <label for="validationCustom01">Answer</label>
                        <input
                          type="text"
                          className="form-control"
                          id="validationCustom01"
                          placeholder=""
                          value={answer}
                          onChange={(event) => {
                            var count = event.target.value;
                            setAnswer(count)
                          }}
                        />
                        <div className="valid-feedback">Looks good!</div>
                      </div>
                    <FormGroup className="mb-0">
                      <div>
                        <Button
                          type="button"
                          color="primary"
                          className="mr-1"
                          onClick={postVideo}
                          disabled={loading}
                        >
                          {loading ?
                           "loading" : "Submit"}
                        </Button>
                      </div>
                    </FormGroup>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Question;