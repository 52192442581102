import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { thunk } from 'redux-thunk';
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';

import authSlice from './slices/auth.slice.js';
import userSlice from './slices/user.slice.js';
import kidSlice from './slices/kid.slice.js';

const rootReducer = combineReducers({
  auth: authSlice,
  user: userSlice,
  kid: kidSlice
});

const persistConfig = {
  key: 'root',
  storage,
  blacklist: []
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
export const store = configureStore({
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ immutableCheck: false, serializableCheck: false }).concat(thunk),
  reducer: persistedReducer,
  devTools: true,
});

export const persistor = persistStore(store);
