import React, { Component, useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  FormGroup,
  Button,
  TabContent,
  TabPane,
  Nav,
  Alert,
  NavItem,
  NavLink,
} from "reactstrap";
// import { activateAuthLayout, GetUsersNow } from "../../../store/actions";
import { withRouter } from "react-router-dom";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import classnames from "classnames";
// import { api } from "../../../helpers/apiLinks";
// import { del, get, post, put } from "../../../helpers/apiCalls";
import Select from "react-select";
// import "@vtaits/react-color-picker/dist/index.css";
import "react-datepicker/dist/react-datepicker.css";
import { MDBDataTable } from "mdbreact";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {TailSpin} from "react-loader-spinner";
import { CSVLink, CSVDownload } from "react-csv";
import SweetAlert from "react-bootstrap-sweetalert";
import { addCategories, deleteCategory, editCategory, getAllCategories } from "../../redux/slices/user.slice";

let images = [
    require("../../assets/img/avatar/avatar-1.png"),
    require("../../assets/img/avatar/avatar-2.png"),
    require("../../assets/img/avatar/avatar-3.png"),
    require("../../assets/img/avatar/avatar-4.png"),
    require("../../assets/img/avatar/avatar-5.png"),
    require("../../assets/img/avatar/avatar-6.png"),
  ];

const Offsymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      No
    </div>
  );
};

const OnSymbol = (props) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        fontSize: 15,
        color: "#fff",
        paddingRight: 2,
      }}
    >
      {" "}
      Yes
    </div>
  );
};

const Category = () => {
const dispatch = useDispatch()
const {category, success} = useSelector(state => state.user)
const [dynamic_title, setDynamicTitle] = useState("");
const [success_dlg, setSuccess_dlg] = useState("");
const [activeTab, setActiveTab] = useState("1");
const [activeTab1, setActiveTab1] = useState("1");
const [alert14, setAlert14] = useState("")
const [loading, setLoading] = useState(false);
const [name, setName] = useState("")
const [serialNo, setSerialNo] = useState("")
const [type, setType] = useState();
const [isEdit, setIsEdit] = useState(false);
const Types = [
    { label: "Special", value: "special" },
    { label: "Classic", value: "classic" },
    { label: "Song", value: "song" },
    { label: "Phonic", value: "phonic" },
  ]
const [isError, setIsError] = useState(false)
const [confirm_both, setConfirm_both] = useState()
const [dynamic_description, setDisplay_description] = useState()
const [confirmBoth, setConfirmBoth] = useState(false);
const [id, setId] = useState("");
const [categoryId, setCategoryId] = useState("");

console.log({success})
//   constructor(props) {
//     super(props);
//     this.state = {
//       activeTab: "1",
//       activeother: "1",
//       startDate: new Date(),
//       users: [],
//       totalUsers: "",
//       schoolCount: "",
//       kidsCount: "",
//       visible: false,
//       modal_standard: false,
//       selectedGroup: null,
//       activeTab1: "5",
//       selectedMulti: null,
//       totalUsers: "",
//       data: [],
//       kids: [],
//       name: "",
//       cateogyr: [],
//       Classes: [
//         {
//           label: "Classes",
//           options: [
//             { label: "Mustard", value: "Mustard" },
//             { label: "Ketchup", value: "Ketchup" },
//             { label: "Relish", value: "Relish" },
//           ],
//         },
//       ],
//       Types: [
//         {
//           label: "Types",
//           options: [
//             { label: "Special", value: "special" },
//             { label: "Classic", value: "classic" },
//             { label: "Song", value: "song" },
//             { label: "Phonic", value: "phonic" },
//           ],
//         },
//       ],
//       isEdit: false,
//       Class: "",
//       serialNo: "",
//       type: "",
//       isError: false,
//       Type: "",
//       isFree: false,
//       selectedFiles: [],
//     };
//     this.toggleStock = this.toggleStock.bind(this);
//     this.toggleMessages = this.toggleMessages.bind(this);
//     this.tog_standard = this.tog_standard.bind(this);
//     this.handleSelectGroup = this.handleSelectGroup.bind(this);
//     this.handleMulti = this.handleMulti.bind(this);
//     this.handleAcceptedFiles = this.handleAcceptedFiles.bind(this);
//   }

 const componentDidMount = () => {
    // this.getClass();
    this.props.activateAuthLayout();
    // this.getAllUser();
    this.getAllCategory();
  }
 const handleAcceptedFiles = (files) => {
    files.map((file) =>
      Object.assign(file, {
        preview: URL.createObjectURL(file),
        formattedSize: this.formatBytes(file.size),
      })
    );

    this.setState({ selectedFiles: files });
  };
  /**
   * Formats the size
   */

 const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

 const getClass = () => {
    let optionGroup = [...this.state.Classes];
    // get(api.CLASS, true)
    //   .then(({ data }) => {
    //     console.log("===>", data);
    //     if (data.classes && data.classes.length) {
    //       // options: [
    //       //     { label: "Mustard", value: "Mustard" },
    //       //     { label: "Ketchup", value: "Ketchup" },
    //       //     { label: "Relish", value: "Relish" },
    //       //   ],
    //       let options = data.classes.map(({ name, _id }) => {
    //         return { label: name, value: _id };
    //       });
    //       console.log({ options });
    //       optionGroup[0]["options"] = options;
    //       this.setState({ Classes: optionGroup });
    //     }
    //   })
    //   .catch((err) => console.log(err));
  };

  //Select
  const handleSelectGroup = (name, selectedGroup) => {
    console.log(name, selectedGroup);
    setType(selectedGroup);
    console.log("IF YOU WANT IT", selectedGroup);
  }
 const handleMulti = (selectedMulti) => {
    this.setState({ selectedMulti });
  };

const  removeBodyCss = () => {
    document.body.classList.add("no_padding");
  }
const  getImage = (image) => {
    let send;
    switch (image) {
      case 1:
        send = images[0];
        break;
      case 2:
        send = images[1];
        break;
      case 3:
        send = images[2];
        break;
      case 4:
        send = images[3];
        break;
      case 5:
        send = images[4];
        break;
      case 6:
        send = images[5];
        break;
      default:
        return images[0];
    }
    return send;
  };
const  tog_standard = (kids) => {
    console.log("DONE");
    this.setState({
      modal_standard: !this.state.modal_standard,
      kids,
    });
    this.removeBodyCss();
  }

const  toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setActiveTab1(
        tab
      );
    }
  };

const  onDelete = (id, i) => {
    let rows = this.state.data.rows;
    let confirm = rows.filter((val) => id !== val._id);
    this.setState({
      data: { ...this.state.data, rows: confirm },
    });

    // del(`${api.USERS}/${id}`, true)
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };

 const toggleStock = (tab) => {
    if (activeTab1 !== tab) {
        setActiveTab1(
          tab
        );
      }
  }

 const toggleMessages = (tab) => {
    if (activeTab1 !== tab) {
        setActiveTab1(
          tab
        );
      }
  }
const  handleCategoryChange = (e) => {
    e.preventDefault();
    // const { Class, name, type } = this.state;
    // const { name, type, categoryId, serialNo } = this.state;
    
    // console.log(
    //   { Class: Class, name, type: type.value },
    //   Class !== "" && name !== "" && type !== ""
    // );
    if (name !== "" && type !== "" && categoryId !== "" && serialNo !== "") {
    //  setLoading(true)
      dispatch(editCategory({name, type: type.value, serialNo, categoryId }))
      if(success === "successfully category edit"){
      setActiveTab1("6")
    }
    //   put(`category/${categoryId}`, { name, type: type.value, serialNo }, true)
    //     .then(({ data }) => {
    //       this.setState({
    //         loading: false,
    //         Class: [],
    //         name: "",
    //         serialNo: "",
    //         isEdit: false,
    //         type: { label: "", value: "" },
    //         activeTab1: "6",
    //       });
    //       this.getAllCategory();
    //       console.log("===>", data);
    //       this.notify("Updated");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.setState({ loading: false });
    //     });
    }
  };
const  addCategory = (e) => {
    e.preventDefault();
    // const { Class, name, type } = this.state;
    // const { name, type, serialNo } = this.state;
    dispatch(addCategories({name, type: type?.value, serialNo}));
    if(success === "successfully category add"){
        setName("")
        setType("")
        setSerialNo("")
    }
    // console.log(
    //   { Class: Class, name, type: type.value },s
    //   Class !== "" && name !== "" && type !== ""
    // );
    // if (name !== "" && type !== "" && serialNo !== "") {
    //   this.setState({ loading: true });
    //   post("category/add", { name, type: type.value, serialNo })
    //     .then(({ data }) => {
    //       this.setState({
    //         isError: false,
    //         loading: false,
    //         Class: [],
    //         name: "",
    //         serialNo: "",
    //         type: { label: "", value: "" },
    //         Type: { label: "", value: "" },
    //       });
    //       this.getAllCategory();
    //       console.log("===>", data);
    //       this.notify("Added");
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //       this.setState({ loading: false });
    //     });
    // } else {
    //   this.setState({
    //     isError: true,
    //   });
    // }
  };

 

useEffect(() => {
    dispatch((getAllCategories()))
},[])

 const getAllCategory = () => {
    // get("category", true)
    //   .then(({ data }) => {
    //     console.log("===>", data);
    //     // this.setState({data})
    //     this.setState({ category: data.category });
    //   })
    //   .catch((err) => console.log(err));
  };

 const handleCategoryDelete = (id) => {
    dispatch(deleteCategory(id))
    // del(`category/${id}`, true)
    //   .then(({ data }) => {
    //     console.log("DELETE SUCCESS", data);
    //     this.setState({
    //       confirm_both: false,
    //       success_dlg: true,
    //       dynamic_title: "Deleted",
    //       dynamic_description: "Your file has been deleted.",
    //     });
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
  };
 const notify = (msg) => toast("Category " + msg + " Succusfully");
 const handleEditClick = (val) => {
    setActiveTab1("5");
    setName(val.name);
    setType({ label: val.type, value: val.type });
    setIsEdit(true);
    setCategoryId(val._id);
    setId(val._id);
    setSerialNo(val.serialNo);
  };

  const handleDeleteClick = (val) => {
    setConfirmBoth(true);
    setId(val._id);
    handleCategoryDelete(val._id)
  };
    const toastify = () => {
      const notify = () => toast("Category Added Succusfully");

      return (
        <div>
          <button onClick={notify}>Notify !</button>
          <ToastContainer />
        </div>
      );
    }
    const rows = React.useMemo(() => 
    category && category?.map((val, index) => {
        return {
            action: (
              <>
                 <Button
        onClick={() => handleEditClick(val)}
        color="primary"
        className="mr-1"
      >
        Edit
      </Button>
      <Button
        onClick={() => handleDeleteClick(val)}
        type="submit"
        color="danger"
        className="mr-1"
      >
        Delete
      </Button>
              </>
            ),
            ...val,
          };
                  })
  , [category]
  );

    const data = React.useMemo(() => ({
        columns: [
            {
              label: "Category Name",
              field: "name",
              sort: "asc",
              width: 270,
            },
            {
              label: "Main-Category",
              field: "type",
              sort: "asc",
              width: 150,
            },
            {
              label: "SerialNo - Order",
              field: "serialNo",
              sort: "asc",
              width: 150,
            },
            {
              label: "Video Count",
              field: "videoCount",
              sort: "asc",
              width: 150,
            },
            {
              label: "Action",
              field: "action",
              sort: "asc",
              width: 270,
            },
          ],
        rows
      }), [rows]);

    return (
      <React.Fragment>
        <div className="content dasboard-content" style={{paddingTop : '80px'}}>
          <div className="container-fluid">
            <div className="page-title-box">
              <div className="row align-items-center">
                <div className="col-sm-6">
                  <h4 className="page-title">Sub Category</h4>
                  <ToastContainer />

                  {confirm_both ? (
                    <SweetAlert
                      title="Are you sure?"
                      warning
                      showCancel
                      confirmBtnBsStyle="danger"
                      cancelBtnBsStyle="success"
                      onConfirm={() => {
                        // deleteCategory(id);
                      }}
                      onCancel={() =>
                        this.setState({
                          confirm_both: false,
                          error_dlg: true,
                          dynamic_title: "Cancelled",
                          dynamic_description: "Your imaginary file is safe :)",
                        })
                      }
                    >
                      You won't be able to revert this!
                    </SweetAlert>
                  ) : null}

                  {success_dlg ? (
                    <SweetAlert
                      success
                      title={dynamic_title}
                      onConfirm={() => setSuccess_dlg(false)}
                    >
                      {dynamic_description}
                    </SweetAlert>
                  ) : null}

                  <ol className="breadcrumb">
                    <li className="breadcrumb-item active">
                      Welcome to Blinkid Category
                    </li>
                  </ol>
                </div>
              </div>
            </div>

            <Nav pills className="navtab-bg nav-justified">
              <NavItem>
                <NavLink
                  className={classnames(
                    activeTab1 === "6",
                  )}
                  onClick={() => {
                    toggle1("5");
                  }}
                >
                  {isEdit ? "Update Category" : "Add Category"}
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames(
                    activeTab1 === "6",
                  )}
                  onClick={() => {
                    toggle1("6");
                  }}
                >
                  Show Category
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab1}>
              <TabPane tabId="5" className="p-3">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col>
                        <Card>
                          <CardBody>
                            {/* <h4 className="mt-0 header-title"></h4> */}
                            {isError && (
                              <Alert
                                color="danger"
                                isOpen={alert14}
                                toggle={() => setAlert14(alert14)}
                              >
                                <i className="mdi mdi-block-helper mr-2"></i>
                                Credential cannot be Empty!
                              </Alert>
                            )}
                            <form>
                              <div className="form-group">
                                <label for="validationCustom01">Name</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="validationCustom01"
                                  placeholder=""
                                  value={name}
                                  onChange={(event) => {
                                    var count = event.target.value;
                                    setName(count);
                                  }}
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                              </div>
                              <div className="form-group">
                                <label for="validationCustom01">
                                  Serial No - Order
                                </label>
                                <input
                                  type="number"
                                  className="form-control"
                                  style={{ width: 80 }}
                                  id="validationCustom01"
                                  placeholder=""
                                  value={serialNo}
                                  onChange={(event) => {
                                    var count = event.target.value;
                                    setSerialNo(count);
                                  }}
                                />
                                <div className="valid-feedback">
                                  Looks good!
                                </div>
                              </div>
                              {/* <div className="form-group">
                                <label className="control-label">Class</label>
                                <Select
                                  value={Class}
                                  isMulti={true}
                                  onChange={(evt) =>
                                    this.handleSelectGroup("Class", evt)
                                  }
                                  options={Classes}
                                />
                              </div> */}
                              <div className="form-group">
                                <label className="control-label">
                                  Select Main Category
                                </label>
                                <Select
      onChange={(selectedGroup) => handleSelectGroup("type", selectedGroup)}
      value={Types.find(option => option.value === type?.value)}
      options={Types}
    />
                              </div>
                              <FormGroup className="mb-0">
                                <div>
                                  <Button
                                    type="submit"
                                    color="primary"
                                    className="mr-1"
                                    onClick={
                                      isEdit
                                        ? handleCategoryChange
                                        : addCategory
                                    }
                                    disable={loading}
                                  >
                                    {loading ? (
                                      <TailSpin
                                      visible={true}
                                      height="80"
                                      width="80"
                                      color="#4fa94d"
                                      ariaLabel="tail-spin-loading"
                                      radius="1"
                                      wrapperStyle={{}}
                                      wrapperClass=""
                                      />
                                    ) : isEdit ? (
                                      "Update"
                                    ) : (
                                      "Submit"
                                    )}
                                  </Button>
                                </div>
                              </FormGroup>
                            </form>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6" className="p-3">
                <Row>
                  <Col sm="12">
                    <Row>
                      <Col>
                        <Card>
                          <CardHeader>
                            <Button className="btn">
                              <CSVLink
                                data={
                                  category &&
                                  category.length > 0
                                    ? category.map((val) => {
                                        return {
                                          // Name: val.name,
                                          "Sub-Category Name": val.name
                                            ? val.name
                                            : "No There is no Parent email Sorry",
                                          Category: val.type,
                                          "Total Video": val.videoCount,
                                          "Created At": moment(
                                            val.createdAt
                                          ).format("DD MMM, YYYY"),
                                        };
                                      })
                                    : []
                                }
                              >
                                Download
                              </CSVLink>
                            </Button>
                          </CardHeader>
                          <CardBody>
                            <MDBDataTable bordered data={data} />
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </div>
      </React.Fragment>
    );
  }

export default Category;
